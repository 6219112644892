"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patientExportColumns = void 0;
const gridUtils_1 = require("../gridUtils");
exports.patientExportColumns = [
    {
        headerName: 'Anrede',
        field: 'geschlecht',
        width: 40,
        excelValueFormatter: gridUtils_1.sexFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Titel',
        field: 'titel',
        width: 60,
    },
    {
        headerName: 'Name',
        field: 'name',
        width: 120,
    },
    {
        headerName: 'Vorname',
        field: 'vorname',
        width: 120,
    },
    {
        headerName: 'Telefon',
        field: 'fon',
        width: 160,
        excelValueFormatter: gridUtils_1.arrayFormatter,
    },
    {
        headerName: 'E-Mail',
        field: 'email',
        width: 160,
    },
    {
        headerName: 'Pat.-ID',
        field: 'patid',
        width: 100,
    },
];
