"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsRoutes = exports.editableSettingsRoutes = void 0;
const types_1 = require("@/../../types");
function lazyLoad(view) {
    return () => Promise.resolve(`${`@/views/${view}.vue`}`).then(s => __importStar(require(s)));
}
exports.editableSettingsRoutes = [
    {
        path: 'daten',
        name: 'Daten',
        component: lazyLoad('Settings/Daten'),
        meta: {
            displayName: 'Praxis',
            requiredFeatures: [types_1.FEATURES.METRICS, ...types_1.R4CFEATURES],
        },
    },
    {
        path: 'leistungen',
        name: 'Leistungen',
        component: lazyLoad('Settings/Leistungen'),
        meta: {
            requiredFeatures: [types_1.FEATURES.METRICS],
        },
    },
    {
        path: 'terminartZuordnung',
        name: 'TerminartZuordnung',
        component: lazyLoad('Settings/TerminartZuordnung'),
        meta: {
            displayName: 'Terminart-Zuordnung',
            requiredFeatures: [types_1.FEATURES.METRICS],
        },
    },
    {
        path: 'behandlerTeams',
        name: 'BehandlerTeams',
        component: lazyLoad('Settings/BehandlerTeams'),
        meta: {
            displayName: 'Behandler/Teams',
            requiredFeatures: [types_1.FEATURES.METRICS],
        },
    },
    {
        path: 'heute',
        name: 'Heute',
        component: lazyLoad('Settings/Heute'),
        meta: {
            requiredFeatures: [types_1.FEATURES.METRICS],
        },
    },
    {
        path: 'datensammlung',
        name: 'Datensammlung',
        component: lazyLoad('Settings/Datensammlung'),
        meta: {
            requiredFeatures: [types_1.FEATURES.METRICS],
        },
    },
    {
        path: 'abrechnung',
        name: 'Abrechnung',
        component: lazyLoad('Settings/Abrechnung'),
        meta: {
            requiredFeatures: [types_1.FEATURES.METRICS],
        },
    },
    {
        path: 'factoring',
        name: 'Factoring',
        component: lazyLoad('Settings/Factoring'),
        meta: {
            requiredFeatures: types_1.FACTORINGFEATURES,
        },
    },
    {
        path: 'anamnese',
        name: 'Anamnese',
        component: lazyLoad('Settings/Anamnese/AnamneseSettings'),
        meta: {
            requiredFeatures: types_1.ANAMNESEFEATURES,
        },
    },
    {
        path: 'workflows',
        name: 'Workflows',
        component: lazyLoad('Settings/Anamnese/WorkflowSettings'),
        meta: {
            requiredFeatures: types_1.ANAMNESEFEATURES,
        },
    },
    {
        path: '*',
        name: 'SettingsRedirect',
        redirect: { name: 'Daten' },
    },
    {
        path: '',
        name: 'SettingsRedirect2',
        redirect: { name: 'Daten' },
    },
];
exports.settingsRoutes = [
    {
        path: 'import',
        name: 'Import',
        component: lazyLoad('Settings/Import'),
        meta: {
            requiredFeatures: [types_1.FEATURES.METRICS],
        },
    },
    {
        path: 'benutzer',
        name: 'Benutzer',
        component: lazyLoad('Settings/Benutzer'),
        meta: {
            checkViewAccess(user) {
                return user.adminLevel >= types_1.ADMIN_LEVEL.CLIENT_ADMIN;
            },
        },
    },
    {
        path: 'tablets',
        name: 'Tablets',
        component: lazyLoad('Settings/Tablets'),
        meta: {
            requiredFeatures: types_1.R4CFEATURES,
        },
    },
    {
        path: 'vertrag',
        name: 'Vertrag',
        component: lazyLoad('Settings/Vertrag'),
    },
    {
        path: '',
        component: lazyLoad('Settings/EditableSettingsLayout'),
        children: exports.editableSettingsRoutes,
    },
];
