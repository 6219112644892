"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var base_1 = require("@rose/base");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {},
      skipHideOption: false,
      skipAlarm: false,
      reiter: null,
      feld: null
    };
  },
  computed: {
    hiddenField: function hiddenField() {
      var _a;

      if (editSettingsStore_1.editSettingsStore.getters.heuteHideFields && this.feld && this.reiter) {
        return (_a = editSettingsStore_1.editSettingsStore.getters.heuteHideFields[this.reiter]) === null || _a === void 0 ? void 0 : _a[this.feld];
      }

      return false;
    },
    counterValue: function counterValue() {
      var _a, _b, _c;

      if (((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey) && ((_b = this.params.colDef) === null || _b === void 0 ? void 0 : _b.field)) {
        var counter = (_c = editSettingsStore_1.editSettingsStore.getters.alarme[this.params.data.feldKey]) === null || _c === void 0 ? void 0 : _c[this.params.colDef.field];
        return counter <= -1 ? '-' : counter;
      }

      return undefined;
    },
    counterColor: function counterColor() {
      if (this.counterValue > 0) {
        return 'primary lighten-1';
      }

      if (this.counterValue === 0) {
        return 'warning';
      }

      if (this.counterValue === '-' && !this.hiddenField) {
        return 'error';
      }

      if (this.counterValue === '-' && this.hiddenField) {
        return 'none';
      }

      return 'primary';
    }
  },
  mounted: function mounted() {
    var _a, _b, _c, _d;

    if (((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey) && ((_b = this.params.colDef) === null || _b === void 0 ? void 0 : _b.field)) {
      this.reiter = (_c = this.params.colDef) === null || _c === void 0 ? void 0 : _c.field;
      this.feld = (_d = this.params.data) === null || _d === void 0 ? void 0 : _d.feldKey;
      this.skipHideOption = common_ui_1.skipHideOptionList.includes(this.feld);
      this.skipAlarm = !(0, base_1.hasAlarm)(this.reiter, this.feld); // console.log(
      //   `countercell (reiter,field,skiphide,skipalarm)`,
      //   this.reiter,
      //   this.feld,
      //   this.skipHideOption,
      //   this.skipAlarm,
      // );
    }
  },
  methods: {
    itemClicked: function itemClicked() {
      if (this.feld) {
        editSettingsStore_1.editSettingsStore.commit.toggleHeuteHideField({
          alarmField: this.reiter,
          alarmType: this.feld
        });
      }
    },
    decrease: function decrease() {
      if (this.counterValue >= -1) {
        editSettingsStore_1.editSettingsStore.commit.decreaseAlarm({
          alarmField: this.feld,
          alarmType: this.reiter
        });
      }
    },
    increase: function increase() {
      editSettingsStore_1.editSettingsStore.commit.increaseAlarm({
        alarmField: this.feld,
        alarmType: this.reiter
      });
    }
  }
});