"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TerminInfoResolverService = void 0;
const common_ui_1 = require("@rose/common-ui");
const data_1 = require("@/services/data");
const lodash_1 = require("lodash");
const clientStateStore_1 = require("@/state/clientStateStore");
class TerminInfoResolverService extends common_ui_1.CommonTerminInfoResolverService {
    constructor() {
        super(...arguments);
        this.name = 'termine';
        this.maxRetries = 5;
    }
    loadInfo(ids, options) {
        if (clientStateStore_1.clientStateStore.getters.isFakeData) {
            return Promise.resolve((0, lodash_1.map)(ids, id => {
                let pn = {
                    extid: id || '',
                    kommentar: common_ui_1.fakerDe.lorem.sentence(10),
                };
                return pn;
            }));
        }
        return data_1.collectorApi.bot.resolveTerminInfos(ids);
    }
}
exports.TerminInfoResolverService = TerminInfoResolverService;
