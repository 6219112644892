"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolgeTermineTerminInfoResolverService = void 0;
const clientStateStore_1 = require("@/state/clientStateStore");
const services_1 = require("../../../common-ui/src/services");
const data_1 = require("./data");
const de_1 = require("@faker-js/faker/locale/de");
const lodash_1 = require("lodash");
class FolgeTermineTerminInfoResolverService extends services_1.CommonFolgeTermineTerminInfoResolverService {
    async loadInfo(ids) {
        if (clientStateStore_1.clientStateStore.getters.isFakeData) {
            return (0, lodash_1.map)(ids, id => {
                let pn = {
                    extid: id,
                    kommentar: de_1.faker.lorem.sentence(10),
                };
                return pn;
            });
        }
        else {
            return data_1.collectorApi.bot.resolveTerminInfos(ids);
        }
    }
}
exports.FolgeTermineTerminInfoResolverService = FolgeTermineTerminInfoResolverService;
