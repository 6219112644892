"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parLeistungen = exports.filterForCPTView = exports.filterForPARView = exports.filterForUPTView = void 0;
const types_1 = require("@rose/types");
const lodash_1 = require("lodash");
function filterForUPTView(ereignisGruppen) {
    const itemsFiltered = (0, lodash_1.chain)(ereignisGruppen)
        .filter(g => [
        types_1.PAREreignisGruppeTyp.REEVALUATION_AIT,
        types_1.PAREreignisGruppeTyp.UPT_I,
        types_1.PAREreignisGruppeTyp.UPT_II,
        types_1.PAREreignisGruppeTyp.UPT_III,
        types_1.PAREreignisGruppeTyp.UPT_IV,
        types_1.PAREreignisGruppeTyp.UPT_V,
        types_1.PAREreignisGruppeTyp.UPT_VI,
        types_1.PAREreignisGruppeTyp.UPT_VII,
    ].includes(g.group))
        .value();
    return (0, lodash_1.chain)(itemsFiltered)
        .reverse()
        .map((g, idx, collection) => {
        if (!hasInterestingEreignisse(g)) {
            // prüfen ob vorgänger ereignisse hat
            if (idx < collection.length - 1) {
                // vorgänger hat ereignisse
                g.hide = !hasInterestingEreignisse(collection[idx + 1]);
                return g;
            }
            g.hide = true;
            return g;
        }
        // prüfen ob aktuelle gruppe in der zukuft liegt, keine vergangeheit oder aktuelles intervall wegfiltern
        if (g.zeit !== types_1.PAREreignisGruppeZeitTyp.ZUKUNFT) {
            g.hide = false;
            return g;
        }
        g.hide = false;
        return g;
    })
        .reverse()
        .value();
}
exports.filterForUPTView = filterForUPTView;
function filterForPARView(ereignisGruppen) {
    return (0, lodash_1.filter)(ereignisGruppen, g => [
        types_1.PAREreignisGruppeTyp.VORBEHANDLUNG,
        types_1.PAREreignisGruppeTyp.HAUPTBEHANDLUNG,
        types_1.PAREreignisGruppeTyp.NACHBEHANDLUNG_BEVA,
    ].includes(g.group) && !(0, lodash_1.isEmpty)(g.ereignisse));
}
exports.filterForPARView = filterForPARView;
function filterForCPTView(ereignisGruppen) {
    return (0, lodash_1.filter)(ereignisGruppen, g => [types_1.PAREreignisGruppeTyp.REEVALUATION_AIT, types_1.PAREreignisGruppeTyp.CPT, types_1.PAREreignisGruppeTyp.REEVALUATION_CPT].includes(g.group) && !(0, lodash_1.isEmpty)(g.ereignisse));
}
exports.filterForCPTView = filterForCPTView;
function parLeistungen(leistungen) {
    return (0, lodash_1.chain)(leistungen)
        .toPairs()
        .sortBy(([nummer]) => sorter(nummer))
        .map(([nummer, infos]) => ((0, lodash_1.toInteger)(infos.anzahl) > 1 ? `${infos.anzahl}x ${nummer}` : nummer))
        .join(', ')
        .value();
}
exports.parLeistungen = parLeistungen;
function hasInterestingEreignisse(g) {
    if (!g) {
        return false;
    }
    return (0, lodash_1.some)(g.ereignisse, e => ![types_1.PAREreignisTyp.UPTTERMINZEITMARKERSTART, types_1.PAREreignisTyp.UPTTERMINZEITMARKERENDE].includes(e.typ));
}
function sorter(nummer) {
    if (!nummer) {
        return 0;
    }
    if (nummer.startsWith('atg')) {
        return 1;
    }
    if (nummer.startsWith('mhu')) {
        return 2;
    }
    if (nummer.startsWith('ait')) {
        return 3;
    }
    if (nummer.startsWith('108')) {
        return 4;
    }
    if (nummer.startsWith('111')) {
        return 5;
    }
    if (nummer.startsWith('bev')) {
        return 6;
    }
    if (nummer.startsWith('upt')) {
        return 7;
    }
    return 10;
}
