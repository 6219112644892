"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.factoringStore = void 0;
const direct_vuex_1 = require("direct-vuex");
const authStore_1 = require("@/state/authStore");
const data_1 = require("@/services/data");
const clientStateStore_1 = require("@/state/clientStateStore");
const factoringState = {
    clientRZs: {},
    praxisDaten: {},
};
const { store, rootActionContext } = (0, direct_vuex_1.createDirectStore)({
    strict: process.env.NODE_ENV !== 'production',
    state: () => factoringState,
    getters: {
        praxisStempelInfo(state) {
            var _a;
            if (!((_a = state.praxisDaten) === null || _a === void 0 ? void 0 : _a.infos)) {
                return undefined;
            }
            return {
                stempelname1: state.praxisDaten.infos.stempelname1,
                stempelname2: state.praxisDaten.infos.stempelname2,
                stempelstrasse: state.praxisDaten.infos.stempelstrasse,
                stempelplz: state.praxisDaten.infos.stempelplz,
                stempelort: state.praxisDaten.infos.stempelort,
                bank1: state.praxisDaten.infos.bank1,
                bic1: state.praxisDaten.infos.bic1,
                fax: state.praxisDaten.infos.fax,
                iban1: state.praxisDaten.infos.iban1,
                kzvnummer: state.praxisDaten.infos.kzvnummer,
            };
        },
    },
    mutations: {
        setClientRZs(state, data) {
            state.clientRZs = data;
        },
        setPraxisDaten(state, data) {
            state.praxisDaten = data;
        },
    },
    actions: {
        async init(context) {
            const { dispatch } = rootActionContext(context);
            const hasR4c = authStore_1.authStore.getters.hasR4c || clientStateStore_1.clientStateStore.getters.isFakeData;
            if (!hasR4c) {
                console.log('no r4c, skipping initialization of factoringStore');
                return;
            }
            await Promise.all([dispatch.initClientRechenzentren(), dispatch.initPraxisDaten()]);
            const { state } = rootActionContext(context);
            if (!state.clientRZs) {
                console.log('initializing factoringStore', state.clientRZs, state.praxisDaten);
            }
        },
        async initClientRechenzentren(context) {
            var _a, _b;
            const { commit } = rootActionContext(context);
            if (!((_a = authStore_1.authStore.state.profile) === null || _a === void 0 ? void 0 : _a.r4chost)) {
                console.warn('no r4cHost, cannot initialize clientRechenzentren');
                return;
            }
            try {
                const clientRZs = await data_1.factoringApi.getClientRechenzentren((_b = authStore_1.authStore.state.profile) === null || _b === void 0 ? void 0 : _b.r4chost);
                commit.setClientRZs(clientRZs);
            }
            catch (error) {
                console.error('error initializing clientRechenzentren', error);
            }
        },
        async initPraxisDaten(context) {
            const { commit } = rootActionContext(context);
            const praxisDaten = await data_1.factoringApi.getPraxisDaten();
            commit.setPraxisDaten(praxisDaten);
        },
    },
});
exports.factoringStore = store;
