import { IUIUser, UserPrivacyLevel } from '../../types';
import { metricsApi } from './services/data';
import { authStore } from './state/authStore';
import { clientStateStore } from './state/clientStateStore';
import { sendCommandToUi } from '@rose/common-ui';

export const isSelfHosted = window.top === window.self;

export async function startUiCommunication() {
  if (isSelfHosted) {
    console.warn('INIT with demo user');
    await initDemo();
    return;
  }

  window.addEventListener('message', msg => {
    handleUiMessage(msg.data);
  });
}

const uiEvents = new EventTarget();

// proxy chrome extension events back to ui
window.addEventListener('message', event => {
  let message = event.data;

  if (message.type === 'OPENINCHARLY') {
    window.top?.postMessage(event.data, '*');
  }
});

export function addUiEventListener(type: string, handler: CallableFunction) {
  let internalHandler = (e: any) => handler(e.detail);
  uiEvents.addEventListener(type, internalHandler);
  return () => uiEvents.removeEventListener(type, internalHandler);
}

async function initDemo() {
  let demoUserCredentials = {
    email: process.env.VUE_APP_USER || 'demo@rose.dental',
    password: process.env.VUE_APP_PASSWORD || '#messbar#',
    cid: process.env.VUE_APP_CLIENT_ID || 'demo',
  };

  try {
    let user = await metricsApi.auth.login(demoUserCredentials, true);

    authStore.commit.setUser(user);
    await clientStateStore.dispatch.setClientId(user.cid);
    if (demoUserCredentials.cid === 'demo') {
      await clientStateStore.dispatch.setUseFakeDataForSensitiveData(UserPrivacyLevel.FAKENAMES);
    }
  } catch (e) {
    console.error('Could not login as demo user', e);
    console.error('No Init possible');
  }
}

export function openLinkInUi(href: string) {
  sendCommandToUi({ command: 'navigate', href });
}

async function handleUiMessage(messageString: string) {
  try {
    let message = JSON.parse(messageString);
    console.log('[vui] ui message', message);
    let command = message.command;

    if (command === 'init') {
      let user: IUIUser = message.user;
      let cid: string = message.cid;
      let host: string = message.host;
      if (!user.token) {
        throw new Error('No token in user');
      }
      authStore.commit.setAuthToken(user.token);
      authStore.commit.setClientId(cid);
      authStore.commit.setUser(user);

      await clientStateStore.dispatch.setClientId(cid);
      await clientStateStore.dispatch.setUseFakeDataForSensitiveData(message.fake);
      clientStateStore.commit.setUiHost(host);
    }

    if (command === 'roseHelperActive') {
      let active = !!message.active;
      clientStateStore.commit.setRoseHelperActive(active);
    }

    if (command === 'isCharlyAnalytics') {
      let active = !!message.active;
      authStore.commit.setIsCharlyAnalytics(active);
    }

    uiEvents.dispatchEvent(new CustomEvent(command, { detail: message }));
  } catch (e) {
    // console.warn('Received wrong formatted message', messageString);
  }
}
