"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    checkColor: function checkColor() {
      var _a;

      return ((_a = this.params.context) === null || _a === void 0 ? void 0 : _a.colorized) ? 'success' : 'primary';
    },
    timesColor: function timesColor() {
      var _a;

      return ((_a = this.params.context) === null || _a === void 0 ? void 0 : _a.colorized) ? 'error' : 'primary';
    }
  }
});