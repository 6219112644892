import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'fa',
    values: {
      cancel: 'fas fa-ban',
      menu: 'fas fa-ellipsis-v',
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#337ab7',
        secondary: '#347ab7',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#337ab7',
        success: '#70b85d',
        warning: '#ec971f',
      },
    },
  },
});
