"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {},
      ascSort: null,
      descSort: null,
      noSort: null
    };
  },
  mounted: function mounted() {
    // console.log('header mounted', this.params);  // FOR DEBUGGING
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    onSortChanged: function onSortChanged() {
      this.ascSort = this.descSort = this.noSort = 'inactive';

      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },
    onSortRequested: function onSortRequested(order, event) {
      this.params.setSort(order, event.shiftKey);
    }
  }
});