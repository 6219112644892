/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center"},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"warning","icon":"","small":""},on:{"click":_vm.abortTask}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-ban")])],1)]}}])},[_c('span',[_vm._v("Aufgabe abbrechen")])]),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"red","icon":"","small":""},on:{"click":_vm.unregisterTablet}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)]}}])},[_c('span',[_vm._v("Tablet löschen")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }