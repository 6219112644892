"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.date.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var leistungenStore_1 = require("@/state/leistungenStore");

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: null
    };
  },
  computed: {
    showExtendedSummaryRow: function showExtendedSummaryRow() {
      return leistungenStore_1.leistungenStore.getters.showExtendedSummaryRow;
    },
    filterActive: function filterActive() {
      if (!leistungenStore_1.leistungenStore.getters.dateEnabled) {
        return false;
      }

      return this.params.data.doFilter.from === leistungenStore_1.leistungenStore.getters.date.startDate && this.params.data.doFilter.to === leistungenStore_1.leistungenStore.getters.date.endDate;
    },
    filterOutOfCurrentDaterange: function filterOutOfCurrentDaterange() {
      if (!leistungenStore_1.leistungenStore.getters.dateEnabled) {
        return false;
      }

      return new Date(this.params.data.doFilter.from) > new Date(leistungenStore_1.leistungenStore.getters.date.endDate) || new Date(this.params.data.doFilter.to) < new Date(leistungenStore_1.leistungenStore.getters.date.startDate);
    }
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    // this.cellValue = this.getValueToDisplay(this.params);
    this.setValues(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues(params);
      return true;
    },
    setValues: function setValues(params) {
      this.params = params;
    }
  }
});