"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadRechnungenExcel = exports.createSummaryRow = void 0;
const lodash_1 = require("lodash");
const base_1 = require("../../../base");
const types_1 = require("../../../types");
const exportExcel_1 = require("./exportExcel");
const gridUtils_1 = require("../gridUtils");
const euro_1 = require("../filters/euro");
const timezone_1 = require("../filters/timezone");
function sumUpKey(list, key) {
    return (0, lodash_1.sumBy)(list, (r) => r[key]);
}
function createSummaryRow(rechnungen, isExtended, title = 'SUMME', doFilter = null, spezialFilter = null, rowFilter) {
    let behandlerSumme = {};
    (0, lodash_1.chain)(rechnungen)
        .flatMap(r => r.honorardetails.behandlerverteilung)
        .compact()
        .forEach(bv => {
        if (!behandlerSumme[bv.behandler]) {
            behandlerSumme[bv.behandler] = { behandler: bv.behandler, betrag: bv.betrag, rest: bv.rest };
        }
        else {
            behandlerSumme[bv.behandler].betrag += bv.betrag;
            behandlerSumme[bv.behandler].rest += bv.rest;
        }
    })
        .value();
    let summaryHonorardetails = (0, lodash_1.values)(behandlerSumme);
    let res = {
        isSummary: true,
        isExtended,
        doFilter,
        spezialFilter,
        isExtendedSummaryRow: title !== 'SUMME',
        title,
        count: rechnungen.length,
        gesamt: sumUpKey(rechnungen, 'gesamt'),
        honorar: sumUpKey(rechnungen, 'honorar'),
        labor: sumUpKey(rechnungen, 'labor'),
        mahnbetrag: sumUpKey(rechnungen, 'mahnbetrag'),
        material: sumUpKey(rechnungen, 'material'),
        materialmwst: sumUpKey(rechnungen, 'materialmwst'),
        mwstnetto: sumUpKey(rechnungen, 'mwstnetto'),
        erstattung: sumUpKey(rechnungen, 'erstattung'),
        rest: sumUpKey(rechnungen, 'rest'),
        filteredLeistungen: [{ betrag: (0, lodash_1.sumBy)(rechnungen, r => (0, lodash_1.sumBy)(r.filteredLeistungen, l => l.betrag)) }],
        honorardetails: { behandlerverteilung: summaryHonorardetails },
        rowFilter,
    };
    return res;
}
exports.createSummaryRow = createSummaryRow;
function downloadRechnungenExcel(columnDefs, rowData, patientSearchInput, statusFilter, rzFilter, team, leistungserbringer, dateFilterStatus, from, to) {
    let wb = (0, exportExcel_1.createWorkBook)();
    let ws = (0, base_1.createEmptyWorkSheet)(wb, 'Rechnungen');
    let exportColumns = columnDefs;
    // find mwst sätze
    const elmwstsaetze = (0, lodash_1.chain)(rowData)
        .flatMap(r => { var _a; return (_a = r.labordetails) === null || _a === void 0 ? void 0 : _a.eigenlabormwstverteilung; })
        .compact()
        .map(m => m.mwstsatz)
        .uniq()
        .sortBy()
        .value();
    const elmmwstsaetze = (0, lodash_1.chain)(rowData)
        .flatMap(r => { var _a; return (_a = r.labordetails) === null || _a === void 0 ? void 0 : _a.eigenlabormaterialmwstverteilung; })
        .compact()
        .map(m => m.mwstsatz)
        .uniq()
        .sortBy()
        .value();
    const mmwstsaetze = (0, lodash_1.chain)(rowData)
        .flatMap(r => { var _a; return (_a = r.materialdetails) === null || _a === void 0 ? void 0 : _a.mwstverteilung; })
        .compact()
        .map(m => m.mwstsatz)
        .uniq()
        .sortBy()
        .value();
    const hmwstsaetze = (0, lodash_1.chain)(rowData)
        .flatMap(r => { var _a; return (_a = r.honorardetails) === null || _a === void 0 ? void 0 : _a.mwstverteilung; })
        .compact()
        .map(m => m.mwstsatz)
        .uniq()
        .sortBy()
        .value();
    // find zahlungsarten
    const zahlungsarten = (0, lodash_1.chain)(rowData)
        .flatMap(r => r.bezahltdetails)
        .compact()
        .map(m => m.zahlungsart)
        .uniq()
        .sortBy()
        .value();
    const nettoFormatter = (ms) => (params) => {
        const x = (0, lodash_1.find)(params.value, v => v.mwstsatz === ms);
        return x ? x.betrag : 0;
    };
    const mwstFormatter = (ms) => (params) => {
        const x = (0, lodash_1.find)(params.value, v => v.mwstsatz === ms);
        return x ? x.mwstbetrag : 0;
    };
    const zahlungsartFormatter = (zahlungsart) => (params) => (0, lodash_1.chain)(params.value)
        .filter(rb => rb.zahlungsart === zahlungsart)
        .sumBy(rb => rb.betrag)
        .value();
    const zahlungenFormatter = () => (params) => (0, lodash_1.chain)(params.value)
        .map(rb => `${timezone_1.tzFilter.filters.tz(rb.tag)}/${timezone_1.tzFilter.filters.tz(rb.fibubuchdatum) || '-'}: ${euro_1.euroFilter.filters.euro(rb.betrag)}`)
        .join(', ')
        .value();
    const zahlungenAnzahlFormatter = () => (params) => (0, lodash_1.size)(params.value);
    exportColumns.push({
        headerName: 'Honorar-Netto',
        valueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.honorar) + '' || '0'; },
        excelValueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.honorar) || 0; },
        useFormatterForExport: true,
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, ...(0, lodash_1.flatMap)(hmwstsaetze, ms => [
        {
            headerName: ms === 0 ? `Honorar-Netto ohne MwSt` : `Honorar-Netto ${ms}%`,
            field: 'honorardetails.mwstverteilung',
            valueFormatter: (params) => nettoFormatter(ms)(params).toString(),
            excelValueFormatter: nettoFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            width: 130,
        },
        {
            headerName: `Honorar-MwSt ${ms}%`,
            field: 'honorardetails.mwstverteilung',
            valueFormatter: (params) => mwstFormatter(ms)(params).toString(),
            excelValueFormatter: mwstFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            width: 130,
            noexport: ms === 0,
        },
    ]), {
        headerName: 'Fremdlabor',
        valueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.fremdlabor) + '' || '0'; },
        excelValueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.fremdlabor) || 0; },
        useFormatterForExport: true,
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Eigenlabor-Netto',
        valueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.eigenlabor) + '' || '0'; },
        excelValueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.eigenlabor) || 0; },
        useFormatterForExport: true,
        width: 100,
        exportUnit: types_1.Units.EURO,
    }, ...(0, lodash_1.flatMap)(elmwstsaetze, ms => [
        {
            headerName: ms === 0 ? `Eigenlabor-Netto ohne MwSt` : `Eigenlabor-Netto ${ms}%`,
            field: 'labordetails.eigenlabormwstverteilung',
            valueFormatter: (params) => nettoFormatter(ms)(params).toString(),
            excelValueFormatter: nettoFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            width: 110,
        },
        {
            headerName: `Eigenlabor-MwSt ${ms}%`,
            field: 'labordetails.eigenlabormwstverteilung',
            valueFormatter: (params) => mwstFormatter(ms)(params).toString(),
            excelValueFormatter: mwstFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            noexport: ms === 0,
            width: 100,
        },
    ]), ...(0, lodash_1.flatMap)(elmmwstsaetze, ms => [
        {
            headerName: ms === 0 ? `Eigenlabor-Material-Netto ohne MwSt` : `Eigenlabor-Material-Netto ${ms}%`,
            field: 'labordetails.eigenlabormaterialmwstverteilung',
            valueFormatter: (params) => nettoFormatter(ms)(params).toString(),
            excelValueFormatter: nettoFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            width: 140,
        },
        {
            headerName: `Eigenlabor-Material-MwSt ${ms}%`,
            field: 'labordetails.eigenlabormaterialmwstverteilung',
            valueFormatter: (params) => mwstFormatter(ms)(params).toString(),
            excelValueFormatter: mwstFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            noexport: ms === 0,
            width: 130,
        },
        {
            headerName: 'Honorar-Netto',
            valueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.honorar) + '' || '0'; },
            excelValueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.honorar) || 0; },
            useFormatterForExport: true,
            width: 85,
            exportUnit: types_1.Units.EURO,
        },
    ]), {
        headerName: 'Material-Netto',
        valueFormatter: (params) => { var _a; return ((_a = params.data) === null || _a === void 0 ? void 0 : _a.material) + '' || '0'; },
        useFormatterForExport: true,
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, ...(0, lodash_1.flatMap)(mmwstsaetze, ms => [
        {
            headerName: ms === 0 ? `Material-Netto ohne MwSt` : `Material-Netto ${ms}%`,
            field: 'materialdetails.mwstverteilung',
            valueFormatter: (params) => nettoFormatter(ms)(params).toString(),
            excelValueFormatter: nettoFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            width: 130,
        },
        {
            headerName: `Material-MwSt ${ms}%`,
            field: 'materialdetails.mwstverteilung',
            valueFormatter: (params) => mwstFormatter(ms)(params).toString(),
            excelValueFormatter: mwstFormatter(ms),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            noexport: ms === 0,
            width: 130,
        },
    ]), ...(0, lodash_1.flatMap)(zahlungsarten, za => [
        {
            headerName: resolveZahlungsArt(za),
            field: 'bezahltdetails',
            valueFormatter: (params) => zahlungsartFormatter(za)(params).toString(),
            excelValueFormatter: zahlungsartFormatter(za),
            useFormatterForExport: true,
            exportUnit: types_1.Units.EURO,
            width: 130,
        },
    ]), {
        headerName: '# Zahlungen',
        field: 'bezahltdetails',
        valueFormatter: (params) => zahlungenAnzahlFormatter()(params).toString(),
        excelValueFormatter: zahlungenAnzahlFormatter(),
        useFormatterForExport: true,
        width: 80,
        exportUnit: types_1.Units.TEXT,
    }, {
        headerName: 'Zahlungen',
        field: 'bezahltdetails',
        valueFormatter: (params) => zahlungenFormatter()(params).toString(),
        excelValueFormatter: zahlungenFormatter(),
        useFormatterForExport: true,
        width: 300,
        exportUnit: types_1.Units.TEXT,
    });
    exportColumns = (0, lodash_1.chain)(exportColumns)
        .compact()
        .reject(cd => !!(cd === null || cd === void 0 ? void 0 : cd.noexport))
        .value();
    // write header
    let colCount = 0;
    let rowCount = 0;
    for (const cd of exportColumns) {
        (0, base_1.createCell)(ws, colCount++, rowCount, cd === null || cd === void 0 ? void 0 : cd.headerName, types_1.Units.NONE, base_1.boldCellStyle);
    }
    // write data
    let sortedRowData = (0, lodash_1.chain)(rowData)
        .sortBy(r => r.tag)
        .reverse()
        .value();
    console.log(`export data`, sortedRowData);
    for (const row of sortedRowData) {
        colCount = 0;
        rowCount++;
        for (const cd of exportColumns) {
            const unit = (0, gridUtils_1.getUnitFromGrid)(cd);
            const val = (0, gridUtils_1.getValueFromGrid)(row, cd);
            (0, base_1.createCell)(ws, colCount++, rowCount, val, unit, unit === types_1.Units.EURO ? (0, base_1.zeroValueLightFGStyle)(val) : undefined);
        }
    }
    let metaData = {
        Filter: patientSearchInput || '-',
        Status: statusFilter,
        'RZ-Filter': rzFilter,
        Stammbehandler: (team === null || team === void 0 ? void 0 : team.name) || '-',
        Leistungerbringer: (leistungserbringer === null || leistungserbringer === void 0 ? void 0 : leistungserbringer.name) || '-',
    };
    let selectedDateFormatted = `${from} - ${to}`;
    if (dateFilterStatus === types_1.RechnungSearchDateFilter.Erstellt) {
        metaData['Erstellt-Datum-Filter'] = selectedDateFormatted;
    }
    if (dateFilterStatus === types_1.RechnungSearchDateFilter.Faellig) {
        metaData['Fällig-Datum-Filter'] = selectedDateFormatted;
    }
    (0, base_1.finalizeSimpleXLSXWithMetadata)(wb, ws, metaData, colCount, rowCount, (0, lodash_1.map)(exportColumns, c => ({ wpx: Math.trunc((c === null || c === void 0 ? void 0 : c.exportWidth) || (c === null || c === void 0 ? void 0 : c.width) || 80) })));
    (0, exportExcel_1.saveXLSX)(wb, 'rechnungen.xlsx');
}
exports.downloadRechnungenExcel = downloadRechnungenExcel;
function resolveZahlungsArt(za) {
    switch (za) {
        case types_1.ZahlungsArtType.NONE:
            return '-';
        case types_1.ZahlungsArtType.BAR:
            return 'Bar';
        case types_1.ZahlungsArtType.SCHECK:
            return 'Scheck';
        case types_1.ZahlungsArtType.EC:
            return 'EC';
        case types_1.ZahlungsArtType.ERSTATTUNG:
            return 'Erstattung';
        case types_1.ZahlungsArtType.BANK1:
            return 'Bank 1';
        case types_1.ZahlungsArtType.BANK2:
            return 'Bank 2';
    }
}
