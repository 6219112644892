"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var authStore_1 = require("@/state/authStore");

var vue_1 = require("vue");

var lodash_1 = require("lodash");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    views: function views() {
      var _this = this;

      return (0, lodash_1.filter)(authStore_1.authStore.getters.viewsAvailable, function (v) {
        var _a, _b;

        return (_b = (_a = _this.params.data) === null || _a === void 0 ? void 0 : _a.views) === null || _b === void 0 ? void 0 : _b.includes(v.id);
      });
    }
  }
});