/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm.params.displayName))]),(_vm.params.context.tooltipText)?_c(VTooltip,{attrs:{"top":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"tooltip-button",attrs:{"x-small":""}},[_vm._v("fas fa-info-circle")])],1)]}}],null,false,1928708403)},[_c('div',{staticClass:"div"},[_vm._v(_vm._s(_vm.params.context.tooltipText))])]):_vm._e(),(_vm.params.enableSorting)?_c('span',{staticClass:"customSortDownLabel",class:_vm.ascSort,on:{"click":function($event){return _vm.onSortRequested("asc", $event)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa fa-arrow-down")])],1):_vm._e(),(_vm.params.enableSorting)?_c('span',{staticClass:"customSortUpLabel",class:_vm.descSort,on:{"click":function($event){return _vm.onSortRequested("desc", $event)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa fa-arrow-up")])],1):_vm._e(),(_vm.params.enableSorting)?_c('span',{staticClass:"customSortRemoveLabel",class:_vm.noSort,on:{"click":function($event){return _vm.onSortRequested(null, $event)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa fa-times")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }