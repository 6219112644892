import { ITerminMitKommentar, ITerminResolveInfo } from '../../../types';

export function handleTMK(
  tmk: ITerminMitKommentar,
  getResolvedInfo: (infoKey: string) => ITerminResolveInfo,
  result: { failedIds: string[] },
): string | undefined {
  let tid = tmk && (tmk.extid || tmk.terminid);
  if (tid) {
    let tinfo: ITerminResolveInfo = getResolvedInfo(tid);
    if (tinfo) {
      if (tmk.kommentar !== tinfo.kommentar) {
        tmk.kommentar = tinfo.kommentar;
        return tid;
      }
    } else {
      result.failedIds.push(tid);
    }
  }
  return undefined;
}
