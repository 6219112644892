/* unplugin-vue-components disabled */import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/settings/Import/PatIds.vue';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.params.node.id)?_c('div',{staticClass:"d-flex"},[(_vm.firstNPatients && _vm.firstNPatients.length > 0)?_c('div',{staticClass:"d-flex align-center"},[(_vm.isLoading)?_c(VOverlay,[_c(VProgressCircular,{attrs:{"indeterminate":"","stream":""}})],1):_vm._e(),_vm._l((_vm.firstNPatients),function(patid){return _c(VChip,{key:patid,staticClass:"mr-1",attrs:{"label":"","small":"","disabled":_vm.isLoading || _vm.patidsCount > 500},on:{"click":function($event){return _vm.showDialog(patid)}}},[_c('span',[_vm._v(_vm._s(patid))])])}),_c(VDialog,{attrs:{"min-width":"700px","max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","label":""}},'v-chip',attrs,false),on),[_c('span',[_vm._v("...")])])]}}],null,false,3048930395),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(__unplugin_components_0,{attrs:{"import-protocol":_vm.importProtocol,"patIds":_vm.patids,"pat-id":_vm.selectedPatId},on:{"close":function($event){_vm.dialog = false},"update":function($event){_vm.selectedPatId = $event}}})],1),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.patidsCount)+" IDs gesamt")])],2):(_vm.errors)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.errors),function(error){return _c(VChip,{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(error),expression:"error",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copySuccess),expression:"copySuccess",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.copyError),expression:"copyError",arg:"error"}],key:error,staticClass:"error-chip",attrs:{"label":"","small":"","color":"error"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(error))])])}),1):_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v("-")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }