"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAbrechnungRegelnOrdered = exports.getOrderedObjects = exports.getReihenfolgeOptions = exports.getReihenfolgeActive = exports.getRzStichworte = exports.demoBotData = exports.emptyBotData = exports.fixupVersionString = exports.getLeistungenAvailable = exports.getLeistungenFiltered = exports.getTerminBehandlerNichtZugeordnet = exports.getBehandlerNichtZugeordnet = exports.getTerminbehandlerMap = exports.getTerminBehandler = void 0;
const behandlerStore_1 = require("@/state/behandlerStore");
const types_1 = require("@rose/types");
const lodash_1 = require("lodash");
const base_1 = require("@rose/base");
const factoringStore_1 = require("@/state/factoringStore");
const staticDataMetricsStore_1 = require("@/state/staticDataMetricsStore");
const editSettingsStore_1 = require("@/state/settings/editSettingsStore");
function getTerminBehandler(settings, extid) {
    return (0, lodash_1.chain)(settings.terminBehandlerZuordnung)
        .pickBy(value => value === extid)
        .keys()
        .map(key => behandlerStore_1.behandlerStore.getters.map[key])
        .value();
}
exports.getTerminBehandler = getTerminBehandler;
function getTerminbehandlerMap(settings, behandler) {
    var _a;
    const configuredBehandler = (0, lodash_1.invertBy)(settings.terminBehandlerZuordnung);
    const blacklistedBehandler = (_a = settings.terminBehandlerZuordnungMeta) === null || _a === void 0 ? void 0 : _a.manuellEntfernt;
    const pendingBehandler = (0, lodash_1.chain)(behandler)
        .filter(b => !!b.behandler && !b.veraltet)
        .reject(b => !!configuredBehandler[b.extid] || (0, lodash_1.includes)(blacklistedBehandler, b.extid))
        .map(b => b.extid)
        .value();
    pendingBehandler.forEach(pb => {
        configuredBehandler[pb] = [];
    });
    return configuredBehandler;
}
exports.getTerminbehandlerMap = getTerminbehandlerMap;
function getBehandlerNichtZugeordnet(settings, behandler, veraltet) {
    const configuredBehandler = settings.behandler || {};
    const behandlerWithTerminbehandler = (0, lodash_1.values)(settings.terminBehandlerZuordnung);
    return (0, lodash_1.chain)(behandler)
        .filter(b => !!b.behandler && b.veraltet === veraltet)
        .reject(b => !!configuredBehandler[b.extid] || (0, lodash_1.includes)(behandlerWithTerminbehandler, b.extid))
        .sortBy(behandlerSorter)
        .value();
}
exports.getBehandlerNichtZugeordnet = getBehandlerNichtZugeordnet;
function getTerminBehandlerNichtZugeordnet(settings, behandler, veraltet) {
    return (0, lodash_1.chain)(behandler)
        .filter(b => !!b.termine && b.veraltet === veraltet)
        .reject(b => (0, lodash_1.chain)(settings.terminBehandlerZuordnung).keys().includes(b.extid).value())
        .sortBy(behandlerSorter)
        .value();
}
exports.getTerminBehandlerNichtZugeordnet = getTerminBehandlerNichtZugeordnet;
const behandlerSorter = b => `${b.displayText}_${b.extid}`;
function getLeistungenFiltered(type) {
    const alleLeistungen = staticDataMetricsStore_1.staticDataMetricsStore.state.bemagoz;
    const leistungenFromSettings = editSettingsStore_1.editSettingsStore.state.editSettings.leistungen[type];
    console.log('leistungenFromSettings', leistungenFromSettings, alleLeistungen);
    // filter all already used leistungen
    return (0, lodash_1.filter)(alleLeistungen, l => (0, lodash_1.some)(leistungenFromSettings, i => [l.nummer, l.abrechnungsnummer].includes((0, lodash_1.toLower)(i))));
}
exports.getLeistungenFiltered = getLeistungenFiltered;
// This is more complex because of the way Vuetify Groups Headers work
function getLeistungenAvailable(type) {
    const alleLeistungen = staticDataMetricsStore_1.staticDataMetricsStore.state.bemagoz;
    const leistungenFromSettings = editSettingsStore_1.editSettingsStore.state.editSettings.leistungen[type];
    // filter all already used leistungen
    const items = (0, lodash_1.reject)(alleLeistungen, l => (0, lodash_1.some)(leistungenFromSettings, i => (0, lodash_1.toLower)(i) === l.nummer));
    // group by BEM/GOZ
    const grouped = (0, lodash_1.groupBy)(items, i => i.typ);
    // create linear liste with headers and elements, ordered
    const ret = [];
    for (const typ of (0, lodash_1.chain)(grouped).keys().sortBy().value()) {
        const typLeistungen = grouped[typ];
        ret.push({ header: typ });
        ret.push(...(0, lodash_1.sortBy)(typLeistungen, l => l.abrechnungsnummer));
    }
    return ret;
}
exports.getLeistungenAvailable = getLeistungenAvailable;
function fixupVersionString(version, pvs) {
    if (version) {
        let l = version.length;
        // charly: TODO: support multiple pvs
        if (l >= 6) {
            let major = l >= 7 ? `${version[l - 7]}${version[l - 6]}` : `${version[l - 6]}`;
            let minor = (0, lodash_1.toInteger)(`${version[l - 5]}${version[l - 4]}${version[l - 3]}`);
            let patch = (0, lodash_1.toInteger)(`${version[l - 2]}${version[l - 1]}`);
            return `${major}.${minor}.${patch}`;
        }
        // z1
        if (l === 3) {
            return `${version[0]}.${version[1]}${version[2]}`;
        }
    }
    return `-`;
}
exports.fixupVersionString = fixupVersionString;
function emptyBotData() {
    return {
        id: 1,
        active: true,
        cid: '-',
        name: '-',
        botId: '-',
        databases: [],
        ipExternal: '-',
        ipInternal: '-',
        subnetMask: '-',
        macaddress: '-',
        mode: types_1.IBotMode.ROSE_BOT,
        monitor: false,
        postgresScan: [],
        pvsscan: [],
        sshPort: 0,
        uptime: undefined,
        version: '-',
        createdAt: undefined,
        updatedAt: undefined,
        alias: '-',
    };
}
exports.emptyBotData = emptyBotData;
function demoBotData() {
    return {
        id: 1,
        active: true,
        cid: 'demodemo',
        name: 'demopi',
        alias: 'demopi',
        botId: '1',
        databases: ['solutiodb', 'postgres'],
        ipExternal: '0.0.0.0',
        ipInternal: '192.168.0.1',
        subnetMask: '255.255.255.0',
        macaddress: '3c:22:fb:be:cb:01',
        mode: types_1.IBotMode.ROSE_BOT,
        monitor: false,
        postgresScan: [],
        pvsscan: [],
        sshPort: 9001,
        uptime: new Date(),
        version: '1',
        createdAt: new Date(),
        updatedAt: new Date(),
    };
}
exports.demoBotData = demoBotData;
function getRzStichworte(config) {
    // transform rz stichworte
    let rzsw = [];
    let stichworte = config.rechenzentrum.stichworte;
    for (const key in stichworte) {
        if (Object.prototype.hasOwnProperty.call(stichworte, key)) {
            const element = stichworte[key];
            rzsw.push(Object.assign(Object.assign({}, element), { stichwort: key }));
        }
    }
    return (0, lodash_1.sortBy)(rzsw, x => x.ava);
}
exports.getRzStichworte = getRzStichworte;
function getReihenfolgeActive(rechzentrumReihenfolge) {
    return rechzentrumReihenfolge.filter((x) => getReihenfolgeOptions().includes(x));
}
exports.getReihenfolgeActive = getReihenfolgeActive;
function getReihenfolgeOptions() {
    var _a, _b, _c;
    const baseKeys = ['stichworte', 'extraseiten'];
    const rzs = (_c = (_b = (_a = factoringStore_1.factoringStore.state.clientRZs) === null || _a === void 0 ? void 0 : _a.rechenzentren) === null || _b === void 0 ? void 0 : _b.map(x => x.rzkey)) !== null && _c !== void 0 ? _c : [];
    return [...baseKeys, ...rzs];
}
exports.getReihenfolgeOptions = getReihenfolgeOptions;
function getOrderedObjects(items, startingFrom) {
    let i = startingFrom || 1;
    const objectArray = [];
    for (const item of items) {
        objectArray.push({
            id: i,
            name: item,
        });
        i += 1;
    }
    return objectArray;
}
exports.getOrderedObjects = getOrderedObjects;
function getAbrechnungRegelnOrdered(settings) {
    var _a, _b, _c;
    let regeln = [];
    const showBeta = true;
    for (const baum of [(0, base_1.regelBaumBema)(), (0, base_1.regelBaumGoz)(true, showBeta)]) {
        for (const gruppe of baum) {
            for (const r of gruppe.regeln) {
                regeln.push({
                    abrechnungsart: r.abrechnungsart,
                    auswahlgruppe: r.auswahlgruppe,
                    beschreibung: r.beschreibung,
                    beschreibungVorschlag: r.beschreibungVorschlag,
                    enabled: r.enabled,
                    ersetzbareLeistungen: r.ersetzbareLeistungen,
                    faktor: r.faktor,
                    fehlendeLeistungen: r.fehlendeLeistungen,
                    id: r.id,
                    konfiguration: r.konfiguration,
                    kurzName: r.kurzName,
                    name: r.name,
                    prio: r.prio,
                    typ: r.typ,
                    zusaetzlicheLeistungErforderlich: r.zusaetzlicheLeistungErforderlich,
                    kategorie: gruppe.name,
                    settings: Object.assign({ fehlendeLeistung: (0, lodash_1.first)(r.fehlendeLeistungen), minAlter: (_a = r.konfiguration) === null || _a === void 0 ? void 0 : _a.minAlter, woAnbieten: (_c = (_b = r.konfiguration) === null || _b === void 0 ? void 0 : _b.woAnbieten) === null || _c === void 0 ? void 0 : _c.default }, settings[r.id]),
                });
            }
        }
    }
    return (0, lodash_1.groupBy)((0, lodash_1.orderBy)(regeln, r => [r.kategorie, r.kurzName], ['asc', 'asc']), 'kategorie');
}
exports.getAbrechnungRegelnOrdered = getAbrechnungRegelnOrdered;
