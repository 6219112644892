"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadExcel = exports.commonCreateSummaryRow = exports.commonCreateExtendedSummaryRow = void 0;
const lodash_1 = require("lodash");
const base_1 = require("../../../base");
const types_1 = require("../../../types");
const exportExcel_1 = require("./exportExcel");
const gridUtils_1 = require("../gridUtils");
// berechnet die einzelnen zellen der unter summenzeile, wo die einzelnen jahre stehen
function commonCreateExtendedSummaryRow(leistungen, summeryRowsConfig, leistungsbehandler) {
    let history = summeryRowsConfig.map(({ yearKey, title, filter, spezialFilter }) => {
        let leistungenYear = (0, lodash_1.map)(leistungen, (l) => l.jahresVerteilung[yearKey]);
        return {
            isSummary: true,
            doFilter: filter,
            spezialFilter,
            isExtended: true,
            honorarerstattung: sumUpKey(leistungenYear, 'honorarerstattung'),
            abrechnungszeitraumoffengoz: (0, lodash_1.chain)(leistungenYear)
                .reject(x => (0, lodash_1.isNil)(x.abrechnungszeitraumoffengoz))
                .meanBy(x => x.abrechnungszeitraumoffengoz)
                .value(),
            honorar: sumUpKey(leistungenYear, 'honorar'),
            material: sumUpKey(leistungenYear, 'material'),
            eigenlabor: sumUpKey(leistungenYear, 'eigenlabor'),
            eigenlabormaterial: sumUpKey(leistungenYear, 'eigenlabormaterial'),
            fremdlabor: sumUpKey(leistungenYear, 'fremdlabor'),
            gesamt: sumUpKey(leistungenYear, 'gesamt'),
            count: sumUpKey(leistungenYear, 'anzahl'),
            title: title,
        };
    });
    return [commonCreateSummaryRow(leistungen, leistungsbehandler), ...history];
}
exports.commonCreateExtendedSummaryRow = commonCreateExtendedSummaryRow;
function sumUpKey(list, key) {
    return (0, lodash_1.sumBy)(list, l => l[key]);
}
function commonCreateSummaryRow(leistungen, leistungsbehandler) {
    // das hier wird die zelle mit den leistungserbringersummen
    let behandler = {};
    (0, lodash_1.each)(leistungen, l => {
        (0, lodash_1.each)(l.leistungsErbringerVerteilung, entry => {
            if (!behandler[entry.behandler]) {
                behandler[entry.behandler] = entry;
            }
            else {
                behandler[entry.behandler].honorar += entry.honorar;
                behandler[entry.behandler].material += entry.material;
                behandler[entry.behandler].eigenlabor += entry.eigenlabor;
                behandler[entry.behandler].eigenlabormaterial += entry.eigenlabormaterial;
                behandler[entry.behandler].fremdlabor += entry.fremdlabor;
                behandler[entry.behandler].gesamt += entry.gesamt;
            }
            // console.log('XXXXXXXXX aaaa', l.patid, entry.behandler, l.gesamt, entry.gesamt, entry);
        });
    });
    let leistungsErbringerVerteilung = (0, lodash_1.values)(behandler);
    // console.log('XXXXXXXXX', leistungsbehandler, leistungen, leistungsErbringerVerteilung);
    let honorarLeistungserbringer = 0;
    if (leistungsbehandler) {
        honorarLeistungserbringer = (0, lodash_1.chain)(leistungen)
            .flatMap(l => l.leistungsErbringerVerteilung)
            .filter(lev => lev.behandler === leistungsbehandler.id)
            .sumBy(e => e.honorar)
            .value();
    }
    // das hier wird die summenzeile
    return {
        isSummary: true,
        isExtended: false,
        count: (0, lodash_1.size)(leistungen),
        title: 'SUMME',
        honorarerstattung: sumUpKey(leistungen, 'honorarerstattung'),
        abrechnungszeitraumoffengoz: (0, lodash_1.chain)(leistungen)
            .reject(x => (0, lodash_1.isNil)(x.abrechnungszeitraumoffengoz))
            .sumBy(x => x.abrechnungszeitraumoffengoz * x.honorar)
            .value() / sumUpKey(leistungen, 'honorar'),
        honorar: sumUpKey(leistungen, 'honorar'),
        material: sumUpKey(leistungen, 'material'),
        eigenlabor: sumUpKey(leistungen, 'eigenlabor'),
        eigenlabormaterial: sumUpKey(leistungen, 'eigenlabormaterial'),
        fremdlabor: sumUpKey(leistungen, 'fremdlabor'),
        gesamt: sumUpKey(leistungen, 'gesamt'),
        leistungsErbringerVerteilung,
        honorarLeistungserbringer,
    };
}
exports.commonCreateSummaryRow = commonCreateSummaryRow;
function downloadExcel(columnDefs, rowData, patientSearchInput, subtractHkp, stammbehandler, leistungsbehandler, dateEnabled, from, to) {
    let wb = (0, exportExcel_1.createWorkBook)();
    let ws = (0, base_1.createEmptyWorkSheet)(wb, 'Leistungen');
    let exportColumns = (0, lodash_1.reject)(columnDefs, cd => cd.noexport);
    exportColumns.push({
        headerName: 'Honorar-MwSt',
        field: 'honorarmwst',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Eigenlabor-MwSt',
        field: 'eigenlabormwst',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Eigenlabor-Material-MwSt',
        field: 'eigenlabormaterialmwst',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Fremdlabor-MwSt',
        field: 'fremdlabormwst',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Material-MwSt',
        field: 'materialmwstoffen',
        width: 85,
        exportUnit: types_1.Units.EURO,
    });
    // write header
    let colCount = 0;
    let rowCount = 0;
    for (const cd of exportColumns) {
        (0, base_1.createCell)(ws, colCount++, rowCount, cd.headerName, types_1.Units.NONE, base_1.boldCellStyle);
    }
    // write data
    let sortedRowData = (0, lodash_1.chain)(rowData)
        .sortBy(r => r.name)
        .value();
    for (const row of sortedRowData) {
        colCount = 0;
        rowCount++;
        for (const cd of exportColumns) {
            (0, base_1.createCell)(ws, colCount++, rowCount, (0, gridUtils_1.getValueFromGrid)(row, cd), (0, gridUtils_1.getUnitFromGrid)(cd));
        }
    }
    let metaData = {
        Filter: patientSearchInput || '-',
        HKP: subtractHkp ? 'ohne' : 'mit',
        Stammbehandler: (stammbehandler === null || stammbehandler === void 0 ? void 0 : stammbehandler.name) || '-',
        Leistungerbringer: (leistungsbehandler === null || leistungsbehandler === void 0 ? void 0 : leistungsbehandler.name) || '-',
    };
    if (dateEnabled) {
        metaData['Datum-Filter'] = `${from} - ${to}`;
    }
    (0, base_1.finalizeSimpleXLSXWithMetadata)(wb, ws, metaData, colCount, rowCount, (0, lodash_1.map)(exportColumns, c => ({ wpx: Math.trunc(c.exportWidth || c.width) })));
    (0, exportExcel_1.saveXLSX)(wb, 'leistungen.xlsx');
}
exports.downloadExcel = downloadExcel;
