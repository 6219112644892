/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./DropdownCell.vue?vue&type=template&id=c8870b92&scoped=true&lang=pug&"
import script from "./DropdownCell.vue?vue&type=script&lang=ts&"
export * from "./DropdownCell.vue?vue&type=script&lang=ts&"
import style0 from "./DropdownCell.vue?vue&type=style&index=0&id=c8870b92&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8870b92",
  null
  
)

export default component.exports