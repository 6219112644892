/* unplugin-vue-components disabled */import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"div"},[_c(VMenu,{attrs:{"bottom":"","min-width":"300px","rounded":"","offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.noColor)?_c(VAvatar,_vm._g({staticClass:"colorpicker",attrs:{"color":_vm.color,"size":"25"}},on)):_vm._e(),(_vm.noColor)?_c(VAvatar,_vm._g({staticClass:"colorpicker",attrs:{"color":"grey lighten-1","size":"25"}},on),[_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fa-solid fa-droplet")])],1):_vm._e()]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VListItemContent,{staticClass:"justify-center mx-2"},[_c(VColorPicker,{staticClass:"mx-12",attrs:{"mode":"hexa"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_c('div',{staticClass:"buttons"},[_c(VBtn,{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"mt-1 error",attrs:{"big":""},on:{"click":_vm.resetColor}},[_vm._v("Verwerfen")]),_c(VBtn,{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"mt-1 primary",attrs:{"big":""},on:{"click":_vm.setColor}},[_vm._v("Speichern")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }