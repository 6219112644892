"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var clientStateStore_1 = require("@/state/clientStateStore");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    patid: {
      type: String,
      required: true
    },
    dialog: {
      type: String,
      "default": ''
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    showRoseHelperRocket: function showRoseHelperRocket() {
      return clientStateStore_1.clientStateStore.state.roseHelperActive;
    }
  },
  mounted: function mounted() {},
  methods: {
    onIconClicked: function onIconClicked() {
      this.$emit('open-patient');
    }
  }
});