"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.behandlerService = exports.BehandlerService = void 0;
const behandlerStore_1 = require("@/state/behandlerStore");
const lodash_1 = require("lodash");
class BehandlerService {
    getProperty(id, field = 'displayText', inputAsFallback = false) {
        let b = behandlerStore_1.behandlerStore.state.map[id];
        if (b) {
            // special case
            if (field === 'typ') {
                return b.info ? (b.info.zahnarzt ? 'ZA' : 'PZR') : '';
            }
            return (0, lodash_1.get)(b, field);
        }
        return inputAsFallback ? id : '-';
    }
    getBehandler(id) {
        return behandlerStore_1.behandlerStore.state.map[id];
    }
    getAllBehandler() {
        return (0, lodash_1.values)(behandlerStore_1.behandlerStore.state.map);
    }
    getAllBehandlerIds() {
        return (0, lodash_1.keys)(behandlerStore_1.behandlerStore.state.map);
    }
    transform(id, field = 'displayText', addId = false, fallback = '?') {
        if (field === 'typ') {
            let b = this.getBehandler(id);
            if (b && b.info) {
                return b.info.zahnarzt ? 'ZA' : b.info.pzr ? 'PZR' : fallback;
            }
            return fallback;
        }
        let s = this.getProperty(id, field);
        if (addId) {
            s += ` (${id})`;
        }
        return (0, lodash_1.isNil)(s) ? fallback : s;
    }
}
exports.BehandlerService = BehandlerService;
exports.behandlerService = new BehandlerService();
