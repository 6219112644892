import _createForOfIteratorHelper from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      parent: null,
      resizeObserver: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    var wrapElm = this.$refs.measure;
    this.parent = wrapElm.parentElement;

    if (wrapElm.parentElement) {
      var resizeObserver = new ResizeObserver(function (entries) {
        var _iterator = _createForOfIteratorHelper(entries),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var entry = _step.value;

            _this.$emit('resize', entry.contentRect);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      });
      resizeObserver.observe(wrapElm.parentElement);
    }
  },
  destroyed: function destroyed() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {}
});