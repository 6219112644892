"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createExtendedSummaryRow = void 0;
const rechnungenStore_1 = require("@/state/rechnungenStore");
const rechnungen_service_1 = require("../../../common-ui/src/directImports/rechnungen.service");
function createExtendedSummaryRow(showExtendedSummaryRow) {
    const rechnungen = [...rechnungenStore_1.rechnungenStore.getters.rechnungenFiltered];
    function createSummaryRowFromSpezialFilter(displayName, filterKey, groupFilterSubKey) {
        var _a, _b;
        let rowFilter = (_b = (_a = rechnungenStore_1.rechnungenStore.getters.filterGroups[filterKey]) === null || _a === void 0 ? void 0 : _a.filters.find(f => f.key === groupFilterSubKey)) === null || _b === void 0 ? void 0 : _b.rowFilter;
        if (!rowFilter) {
            throw new Error(`spezial filter ${displayName}: ${[filterKey, groupFilterSubKey].join('.')} not found`);
        }
        return (0, rechnungen_service_1.createSummaryRow)(rechnungen.filter(rowFilter), showExtendedSummaryRow, displayName, null, groupFilterSubKey ? [`${filterKey}.${groupFilterSubKey}`] : [filterKey], rowFilter);
    }
    const zahlungszielUeberschritten = createSummaryRowFromSpezialFilter('Zahlungsziel überschritten', 'zahlungsziel', 'zahlungszielUeberschritten');
    const zahlungszielZukunft = createSummaryRowFromSpezialFilter('Zahlungsziel in der Zukunft', 'zahlungsziel', 'zahlungszielZukunft');
    const mahnung1 = createSummaryRowFromSpezialFilter('   1. Mahnung', 'mahnung', 'mahnung1');
    const mahnung2 = createSummaryRowFromSpezialFilter('   2. Mahnung', 'mahnung', 'mahnung2');
    const mahnung3 = createSummaryRowFromSpezialFilter('   3. Mahnung', 'mahnung', 'mahnung3');
    const anwalt = createSummaryRowFromSpezialFilter('   Anwalt', 'mahnung', 'mahnung4');
    const realisierbar = createSummaryRowFromSpezialFilter('Realisierbar', 'realisierbar', 'realisierbar');
    const verjahrtJahresende = createSummaryRowFromSpezialFilter('   Verjährt Jahresende', 'realisierbar', 'verjahrtJahresende');
    const verjahrt = createSummaryRowFromSpezialFilter('Verjährt', 'realisierbar', 'verjahrt');
    const rechenzentrum = createSummaryRowFromSpezialFilter('Rechenzentrum', 'rz', 'rz');
    return [
        (0, rechnungen_service_1.createSummaryRow)(rechnungen, showExtendedSummaryRow),
        zahlungszielUeberschritten,
        zahlungszielZukunft,
        mahnung1,
        mahnung2,
        mahnung3,
        anwalt,
        realisierbar,
        verjahrtJahresende,
        verjahrt,
        rechenzentrum,
    ];
}
exports.createExtendedSummaryRow = createExtendedSummaryRow;
