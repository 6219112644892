"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    aktiv: {
      get: function get() {
        var _a, _b;

        return !((_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.deaktiviert);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore_1.editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              deaktiviert: !value
            }
          });
        }
      }
    }
  }
});