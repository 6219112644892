"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notifyError = exports.notifyWarning = exports.notifySuccess = exports.notifyInfo = void 0;
const common_ui_1 = require("@rose/common-ui");
function notifyInfo(message) {
    (0, common_ui_1.sendCommandToUi)({ command: 'notify', level: 0, message });
}
exports.notifyInfo = notifyInfo;
function notifySuccess(message) {
    (0, common_ui_1.sendCommandToUi)({ command: 'notify', level: 1, message });
}
exports.notifySuccess = notifySuccess;
function notifyWarning(message) {
    (0, common_ui_1.sendCommandToUi)({ command: 'notify', level: 2, message });
}
exports.notifyWarning = notifyWarning;
function notifyError(message) {
    (0, common_ui_1.sendCommandToUi)({ command: 'notify', level: 3, message });
}
exports.notifyError = notifyError;
