"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NameResolverService = void 0;
const services_1 = require("@rose/common-ui/src/services");
const data_1 = require("./data");
class NameResolverService extends services_1.CommonNameResolverService {
    async loadInfo(ids, options) {
        return data_1.collectorApi.bot.resolveNames({ ids, withPAR: options.withPAR, withAddress: options.withAddress });
    }
    setFakeData(privacyLevel) {
        this.fakeData = privacyLevel;
    }
}
exports.NameResolverService = NameResolverService;
