"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.trim.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.array.find.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var types_1 = require("@rose/types");

var lodash_1 = require("lodash");

var common_ui_1 = require("@rose/common-ui");

var base_1 = require("@rose/base");

var parFilters_1 = require("@/helpers/parFilters");

exports["default"] = (0, vue_1.defineComponent)({
  filters: {
    parPhase: function parPhase(phase) {
      return (0, base_1.translateHKPParPhase)(phase);
    },
    parLeistungen: function parLeistungen(leistungen) {
      return (0, parFilters_1.parLeistungen)(leistungen);
    },
    vorschlaege: function vorschlaege(_vorschlaege) {
      return (0, lodash_1.chain)(_vorschlaege).orderBy([function (v) {
        return v.anzahlplus;
      }, function (v) {
        return v.regel;
      }], ['desc', 'asc']).map(function (v) {
        return (0, lodash_1.toInteger)(v.anzahlplus) > 1 ? "".concat(v.anzahlplus, "x ").concat(v.regel) : v.regel;
      }).join(', ').value();
    }
  },
  props: {
    par: {
      type: Array,
      required: true
    },
    stichworte: {
      type: Array,
      required: false,
      "default": function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      PAREreignisPhaseTyp: types_1.PAREreignisPhaseTyp,
      isProd: false,
      selectedView: types_1.PAREreignisPhaseTyp.PAR,
      disableUPTEndFilter: false,
      showMoreButton: false,
      showLessButton: false,
      selectedPar: undefined
    };
  },
  computed: {
    showStichworte: function showStichworte() {
      return !(0, lodash_1.chain)(this.stichworte).map(function (s) {
        return (0, lodash_1.trim)(s.kuerzel);
      }).filter(function (s) {
        return s.length > 0;
      }).isEmpty();
    },
    canSeeHKPMan: function canSeeHKPMan() {
      return true; // TODO: return authStore.getters.viewsAvailable ? authStore.getters.viewsAvailable.includes(ViewID.HKP_MANAGER) : false;
    },
    parGruppenUnfiltered: function parGruppenUnfiltered() {
      if (!this.selectedPar) {
        return [];
      }

      return (0, lodash_1.map)(this.selectedPar.gruppen, function (g) {
        return Object.assign(Object.assign({}, g), {
          hide: false
        });
      });
    },
    parGruppenFiltered: function parGruppenFiltered() {
      if (this.selectedView === types_1.PAREreignisPhaseTyp.PAR) {
        return (0, parFilters_1.filterForPARView)(this.parGruppenUnfiltered);
      } else if (this.selectedView === types_1.PAREreignisPhaseTyp.CPT) {
        return (0, parFilters_1.filterForCPTView)(this.parGruppenUnfiltered);
      } else if (this.selectedView === types_1.PAREreignisPhaseTyp.UPT) {
        var fr = (0, parFilters_1.filterForUPTView)(this.parGruppenUnfiltered);
        return this.disableUPTEndFilter ? fr : (0, lodash_1.reject)(fr, function (i) {
          return i.hide;
        });
      }

      return [];
    }
  },
  beforeMount: function beforeMount() {
    this.isProd = common_ui_1.isProd;
    this.selectedPar = (0, lodash_1.first)(this.par);
  },
  methods: {
    update: function update() {
      if (this.selectedPar && this.$options.filters) {
        if (this.selectedView === types_1.PAREreignisPhaseTyp.PAR) {
          this.showMoreButton = false;
          this.showLessButton = false;
        } else if (this.selectedView === types_1.PAREreignisPhaseTyp.CPT) {
          this.showMoreButton = false;
          this.showLessButton = false;
        } else if (this.selectedView === types_1.PAREreignisPhaseTyp.UPT) {
          var fr = (0, parFilters_1.filterForUPTView)(this.parGruppenUnfiltered);
          this.showMoreButton = this.disableUPTEndFilter ? false : (0, lodash_1.some)(fr, function (i) {
            return i.hide;
          });
          this.showLessButton = this.disableUPTEndFilter ? (0, lodash_1.some)(fr, function (i) {
            return i.hide;
          }) : false;
        }
      }
    },
    toggleFilter: function toggleFilter() {
      this.disableUPTEndFilter = !this.disableUPTEndFilter;
      this.update();
    },
    copy: function copy(text) {
      console.log(text);
    },
    onChange: function onChange(event) {
      var id = event.target.value;
      var isChanged = this.selectedPar ? this.selectedPar.parstatus.hkp !== id : false;
      this.selectedPar = (0, lodash_1.find)(this.par, function (p) {
        return p.parstatus.hkp === id;
      });
      console.log('selected par:', id, this.selectedPar);
    }
  }
});