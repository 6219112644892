"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsImportProtocolColumnsDefs = exports.settingsImportBotProtocolColumnsDefs = exports.settingsImportCollectorProtocolColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
const base_1 = require("../../../../../base");
const lodash_1 = require("lodash");
function settingsImportCollectorProtocolColumnsDefs(incremental) {
    return settingsImportProtocolColumnsDefs(true, incremental);
}
exports.settingsImportCollectorProtocolColumnsDefs = settingsImportCollectorProtocolColumnsDefs;
function settingsImportBotProtocolColumnsDefs(incremental) {
    return settingsImportProtocolColumnsDefs(true, incremental);
}
exports.settingsImportBotProtocolColumnsDefs = settingsImportBotProtocolColumnsDefs;
function settingsImportProtocolColumnsDefs(withSize, withPatids) {
    const cols = [
        {
            headerName: 'Modell',
            field: 'name',
            resizable: true,
            sortable: true,
            minWidth: 140,
        },
        withPatids
            ? {
                headerName: '# Patienten',
                valueGetter: params => { var _a; return (0, lodash_1.keys)((_a = params.data) === null || _a === void 0 ? void 0 : _a.patids).length; },
                resizable: true,
                sortable: true,
                minWidth: 80,
                cellStyle: { 'justify-content': 'end' },
                flex: 1,
            }
            : undefined,
        {
            headerName: '# Elemente',
            valueGetter: params => { var _a, _b; return (0, lodash_1.sumBy)((0, lodash_1.values)((_a = params.data) === null || _a === void 0 ? void 0 : _a.patids), p => (0, lodash_1.size)(p)) || ((_b = params.data) === null || _b === void 0 ? void 0 : _b.count); },
            valueFormatter: params => { var _a, _b; return common_ui_1.decimalFilter.filters.decimal((0, lodash_1.sumBy)((0, lodash_1.values)((_a = params.data) === null || _a === void 0 ? void 0 : _a.patids), p => (0, lodash_1.size)(p)) || ((_b = params.data) === null || _b === void 0 ? void 0 : _b.count) || 0); },
            resizable: false,
            minWidth: 80,
            cellStyle: { 'justify-content': 'end' },
            flex: 1,
        },
        {
            headerName: 'Zeit',
            valueGetter: params => { var _a; return (_a = params.data) === null || _a === void 0 ? void 0 : _a.started; },
            valueFormatter: params => { var _a, _b; return `${(0, base_1.roseDayjs)((_a = params.data) === null || _a === void 0 ? void 0 : _a.started).format('HH:mm:ss')} - ${(0, base_1.roseDayjs)((_b = params.data) === null || _b === void 0 ? void 0 : _b.ended).format('HH:mm:ss')}`; },
            resizable: true,
            minWidth: 120,
            flex: 1,
        },
        {
            headerName: 'Dauer',
            field: 'duration',
            valueFormatter: common_ui_1.dauerFromSecondsFormatter,
            resizable: true,
            sort: 'desc',
            minWidth: 80,
            flex: 1,
        },
        withSize
            ? {
                headerName: 'Größe',
                field: 'size',
                valueFormatter: common_ui_1.binarySizeFormatter,
                resizable: true,
                minWidth: 100,
                flex: 1,
            }
            : undefined,
    ];
    return (0, lodash_1.compact)(cols);
}
exports.settingsImportProtocolColumnsDefs = settingsImportProtocolColumnsDefs;
