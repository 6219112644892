import router from '@/router';
import { addUiEventListener, isSelfHosted } from '@/uiCommunication';
import { IViewStateProvider } from '@rose/common-ui/src/directives/v-model-bind-view-state';
import { createDirectStore } from 'direct-vuex';
import { sendCommandToUi } from '@rose/common-ui';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export interface ViewState {
  [key: string]: string | undefined;
}

const { store } = createDirectStore({
  state: () => ({
    viewState: {} as ViewState,
  }),
  getters: {
    viewState(state) {
      return state.viewState;
    },
  },
  mutations: {
    _setViewStateInternal(state, viewState: ViewState) {
      state.viewState = viewState;
    },
  },
  actions: {
    async setViewState(context, viewState: ViewState) {
      sendCommandToUi({ command: 'setViewState', viewState });

      if (isSelfHosted) {
        await router.push({ query: { ...viewState } });
      }
    },
    async addToViewState(context, viewState: ViewState) {
      sendCommandToUi({ command: 'addToViewState', viewState });

      if (isSelfHosted) {
        await router.push({ query: { ...context.state.viewState, ...viewState } });
      }
    },
    async removeFromViewState(context, deleteKeys: string[]) {
      sendCommandToUi({ command: 'removeFromViewState', deleteKeys });

      if (isSelfHosted) {
        let copiedViewState = { ...context.state.viewState };
        deleteKeys.forEach(key => {
          delete copiedViewState[key];
        });
        await router.push({ query: { ...copiedViewState } });
      }
    },
    async addAndRemoveFromViewState(context, args: { viewState: ViewState; deleteKeys: string[] }) {
      sendCommandToUi({ command: 'addAndRemoveFromViewState', args });

      if (isSelfHosted) {
        let copiedViewState = { ...context.state.viewState, ...args.viewState };
        args.deleteKeys.forEach(key => {
          delete copiedViewState[key];
        });
        await router.push({ query: { ...copiedViewState } });
      }
    },
    async clearViewState() {
      sendCommandToUi({ command: 'clearViewState' });

      if (isSelfHosted) {
        await router.push({ query: {} });
      }
    },
  },
});

if (isSelfHosted) {
  router.afterEach(to => {
    let query = to.query;
    let filtererQuery = Object.fromEntries(
      Object.entries(query).filter(([, value]) => typeof value === 'string'),
    ) as ViewState;

    store.commit._setViewStateInternal(filtererQuery);
    emitViewStateEvents(filtererQuery);
  });
}

let viewStateEventTarget = new EventTarget();
addUiEventListener('setViewState', (message: any) => {
  let viewState: ViewState = message.viewState;
  store.commit._setViewStateInternal(viewState);

  emitViewStateEvents(viewState);
});

function emitViewStateEvents(viewState: ViewState) {
  viewStateEventTarget.dispatchEvent(new CustomEvent('__wholeViewState', { detail: viewState }));
  Object.keys(viewState).forEach(key =>
    viewStateEventTarget.dispatchEvent(new CustomEvent(key, { detail: viewState[key] })),
  );
}

export function subscribeToViewState(handler: CallableFunction) {
  return subscribeToViewStateField('__wholeViewState', handler);
}

export function subscribeToViewStateField(key: string, handler: CallableFunction) {
  let internalHandler = (e: any) => handler(e.detail);
  viewStateEventTarget.addEventListener(key, internalHandler);
  return () => viewStateEventTarget.removeEventListener(key, internalHandler);
}

export const viewStateProvider: IViewStateProvider = {
  getViewStateKey(key: string): string | string[] | undefined {
    return store.getters.viewState[key];
  },
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  async setViewStateKey(key: string, value: string | string[]) {
    await store.dispatch.addToViewState({ [key]: value as string });
  },
  subscribeToViewStateKey(key: string, handler: (value: string | string[]) => void): CallableFunction {
    return subscribeToViewStateField(key, handler);
  },
};

export const viewStateStore = store;
// @ts-ignore
window.viewStateStore = store;
