"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createExtendedSummaryRow = exports.createSummaryRow = void 0;
const leistungenStore_1 = require("@/state/leistungenStore");
const base_1 = require("../../../base");
const leistungen_service_1 = require("@rose/common-ui/src/directImports/leistungen.service");
const summeryRowsConfig = [
    {
        title: (0, base_1.roseDayjs)().year(),
        yearKey: 'yearCurrent',
        filter: {
            from: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year(), 0, 1]).format('YYYY-MM-DD'),
            to: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year(), 11, 31]).format('YYYY-MM-DD'),
        },
    },
    {
        title: (0, base_1.roseDayjs)().year() - 1,
        yearKey: 'year1Prev',
        filter: {
            from: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year() - 1, 0, 1]).format('YYYY-MM-DD'),
            to: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year() - 1, 11, 31]).format('YYYY-MM-DD'),
        },
    },
    {
        title: (0, base_1.roseDayjs)().year() - 2,
        yearKey: 'year2Prev',
        filter: {
            from: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year() - 2, 0, 1]).format('YYYY-MM-DD'),
            to: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year() - 2, 11, 31]).format('YYYY-MM-DD'),
        },
    },
    {
        title: (0, base_1.roseDayjs)().year() - 3,
        yearKey: 'year3Prev',
        filter: {
            from: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year() - 3, 0, 1]).format('YYYY-MM-DD'),
            to: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year() - 3, 11, 31]).format('YYYY-MM-DD'),
        },
    },
    {
        title: 'verjährt',
        yearKey: 'yearOlder3',
        filter: {
            from: (0, base_1.roseDayjs)([1970, 0, 1]).format('YYYY-MM-DD'),
            to: (0, base_1.roseDayjs)([(0, base_1.roseDayjs)().year() - 4, 11, 31]).format('YYYY-MM-DD'),
        },
    },
];
function createSummaryRow() {
    return (0, leistungen_service_1.commonCreateSummaryRow)(leistungenStore_1.leistungenStore.getters.leistungenFiltered, leistungenStore_1.leistungenStore.getters.leistungserbringerSelected);
}
exports.createSummaryRow = createSummaryRow;
function createExtendedSummaryRow() {
    return (0, leistungen_service_1.commonCreateExtendedSummaryRow)(leistungenStore_1.leistungenStore.getters.leistungenFiltered, summeryRowsConfig, leistungenStore_1.leistungenStore.getters.leistungserbringerSelected);
}
exports.createExtendedSummaryRow = createExtendedSummaryRow;
