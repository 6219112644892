"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.runRequestStateGetter = exports.provideAuthStateGetter = exports.provideRoseApi = exports.getCollectorApi = exports.getMetricsApi = exports.getR4cApi = void 0;
const api_1 = require("../api");
let r4cApi = null;
let metricsApi = null;
let collectorApi = null;
function getR4cApi() {
    if (!r4cApi)
        throw new Error('r4cApi not initialized');
    return r4cApi;
}
exports.getR4cApi = getR4cApi;
function getMetricsApi() {
    if (!metricsApi)
        throw new Error('metricsApi not initialized');
    return metricsApi;
}
exports.getMetricsApi = getMetricsApi;
function getCollectorApi() {
    if (!collectorApi)
        throw new Error('collectorApi not initialized');
    return collectorApi;
}
exports.getCollectorApi = getCollectorApi;
function provideRoseApi(roseApi) {
    r4cApi = (0, api_1.createR4cApi)(roseApi);
    metricsApi = (0, api_1.createMetricsApi)(roseApi);
    collectorApi = (0, api_1.createCollectorApi)(roseApi);
}
exports.provideRoseApi = provideRoseApi;
let authRequestStateGetter = null;
function provideAuthStateGetter(_authRequestStateGetter) {
    authRequestStateGetter = _authRequestStateGetter;
}
exports.provideAuthStateGetter = provideAuthStateGetter;
function runRequestStateGetter() {
    if (!authRequestStateGetter)
        throw new Error('authRequestStateGetter not initialized');
    return authRequestStateGetter();
}
exports.runRequestStateGetter = runRequestStateGetter;
