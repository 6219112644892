"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.staticDataMetricsStore = void 0;
const direct_vuex_1 = require("direct-vuex");
const data_1 = require("@/services/data");
const lodash_1 = require("lodash");
const settings_1 = require("@/helpers/settings");
const staticDataMetricsState = {
    params: {},
    bemagoz: {},
    terminarten: {},
    termingruppen: {},
};
const { store, rootActionContext } = (0, direct_vuex_1.createDirectStore)({
    strict: process.env.NODE_ENV !== 'production',
    state: () => staticDataMetricsState,
    getters: {
        termingruppenNamen(state) {
            return (0, lodash_1.sortBy)(state.termingruppen, v => v.name).map(v => v.name);
        },
        hasHelloCharlyInterface(state) {
            var _a, _b;
            return (_b = (_a = state.params) === null || _a === void 0 ? void 0 : _a.schnittstellen) === null || _b === void 0 ? void 0 : _b.includes('ExportSolutioSpeech');
        },
        hasR4cInterface(state) {
            var _a, _b;
            return (_b = (_a = state.params) === null || _a === void 0 ? void 0 : _a.schnittstellen) === null || _b === void 0 ? void 0 : _b.includes('ExportRose4Charly');
        },
        hasMultiRecallInterface(state) {
            return state.params.multirecall;
        },
    },
    mutations: {
        setParams(state, data) {
            state.params = data;
        },
        setBemaGoz(state, data) {
            state.bemagoz = data;
        },
        setTerminarten(state, data) {
            state.terminarten = data;
        },
        setTermingruppen(state, data) {
            state.termingruppen = data;
        },
    },
    actions: {
        async init(context) {
            const { state, commit } = rootActionContext(context);
            const [params, bemaGoz, terminArten, terminGruppen] = await Promise.all([
                data_1.metricsApi.staticData.getParams(),
                data_1.metricsApi.staticData.getBemaGoz(),
                data_1.metricsApi.staticData.getTerminarten(),
                data_1.metricsApi.staticData.getTermingruppen(),
            ]);
            if (state.bemagoz.length === 0 ||
                state.terminarten.length === 0 ||
                state.termingruppen.length === 0 ||
                (0, lodash_1.isEmpty)(state.params)) {
                console.log('initializing staticDataMetricsState', params, bemaGoz, terminArten, terminGruppen);
            }
            else {
                console.log('reset editProfileState', params, bemaGoz, terminArten, terminGruppen);
            }
            commit.setParams(Object.assign(Object.assign({}, params), { pvsversion: (0, settings_1.fixupVersionString)(params.pvsversion) }));
            commit.setBemaGoz(bemaGoz);
            commit.setTerminarten(terminArten);
            commit.setTermingruppen(terminGruppen);
        },
    },
});
exports.staticDataMetricsStore = store;
