"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonNameResolverService = void 0;
const types_1 = require("../../../types");
const fakerDe_1 = require("../fakerDe");
const base_1 = require("../../../base");
const base_resolver_service_1 = require("./base-resolver.service");
const utils_1 = require("../utils");
class CommonNameResolverService extends base_resolver_service_1.BaseResolverService {
    constructor() {
        super(...arguments);
        this.name = 'namen';
        this.maxRetries = 30;
    }
    extractDataObjects(entry) {
        return entry ? [entry] : [];
    }
    extractId(entry) {
        return entry.patid;
    }
    extractInfoKey(item) {
        return item ? item.extid : undefined;
    }
    fakeInfo(dobjs) {
        const sex = fakerDe_1.fakerDe.name.sexType();
        const patInfos = dobjs.map(o => {
            let pn = {
                extid: o.patid,
                titel: (0, utils_1.fakeTitle)(),
                name: fakerDe_1.fakerDe.name.lastName(sex),
                vorname: fakerDe_1.fakerDe.name.firstName(sex),
                veraltet: fakerDe_1.fakerDe.datatype.boolean(),
                privat: fakerDe_1.fakerDe.datatype.boolean(),
                geburtstag: fakerDe_1.fakerDe.date.past(50),
                geschlecht: sex === 'male' ? types_1.GeschlechtType.MALE : types_1.GeschlechtType.FEMALE,
                telefon: [fakerDe_1.fakerDe.phone.number()],
                email: fakerDe_1.fakerDe.internet.email(),
            };
            return pn;
        });
        return Promise.resolve(patInfos);
    }
    blurInfo(dobjs) {
        const sex = fakerDe_1.fakerDe.name.sexType();
        return Promise.resolve(dobjs.map(o => {
            let pn = {
                extid: o.patid,
                name: '******',
                vorname: '******',
                veraltet: fakerDe_1.fakerDe.datatype.boolean(),
                privat: fakerDe_1.fakerDe.datatype.boolean(),
                geburtstag: fakerDe_1.fakerDe.date.past(50),
                geschlecht: sex === 'male' ? types_1.GeschlechtType.MALE : types_1.GeschlechtType.FEMALE,
                telefon: [fakerDe_1.fakerDe.phone.number()],
                email: fakerDe_1.fakerDe.internet.email(),
            };
            return pn;
        }));
    }
    destroy() { }
    setResolvedInfo(e, getResolvedInfo) {
        let result = { changedIds: [], failedIds: [] };
        let patid = e.patid;
        if (patid) {
            let nameInfo = getResolvedInfo(patid);
            if (nameInfo) {
                // check if we really need this
                if (e.name !== nameInfo.name) {
                    let bdayMoment = nameInfo.geburtstag && (0, base_1.roseDayjs)(nameInfo.geburtstag);
                    e.titel = nameInfo.titel;
                    e.name = nameInfo.name;
                    e.vorname = nameInfo.vorname;
                    e.veraltet = nameInfo.veraltet;
                    e.privatpatient = nameInfo.privat;
                    e.geschlecht = nameInfo.geschlecht;
                    e.par = nameInfo.par;
                    e.fon = nameInfo.telefon;
                    e.email = nameInfo.email;
                    if (bdayMoment) {
                        e.geburtstag = bdayMoment.toDate();
                        e.alterHeute = (0, base_1.roseDayjs)().diff(bdayMoment, 'years');
                    }
                    result.changedIds.push(patid);
                }
            }
            else {
                result.failedIds.push(patid);
            }
        }
        return result;
    }
}
exports.CommonNameResolverService = CommonNameResolverService;
