import "core-js/modules/es.date.to-string.js";
import { authStore } from '@/state/authStore';
import { defineComponent } from 'vue';
import { roseDayjs } from '../../../base';
import { osterSonntagAsUTCDate } from '../../../base';
export default defineComponent({
  props: {
    loadingText: {
      type: String,
      "default": 'Lade...'
    }
  },
  data: function data() {
    return {
      authStoreState: authStore.state,
      showSantaHat: new Date().getMonth() === 11 && new Date().getDate() >= 6
    };
  },
  computed: {
    showEasterBunny: function showEasterBunny() {
      var ostern = roseDayjs(osterSonntagAsUTCDate(new Date().getFullYear()));
      return roseDayjs().isBetween(ostern.clone().subtract(7, 'days'), ostern.clone().add(3, 'days'));
    }
  }
});