"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  methods: {
    deleteItem: function deleteItem() {
      this.params.context.clicked({
        row: this.params.data,
        column: this.params.value
      });
    }
  }
});