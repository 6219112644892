"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vueI18nConfig = exports.LOCALE_KEY_TO_LANGUAGE_NAME = exports.i18nMessages = void 0;
const i18nAnamnese_1 = require("./i18nAnamnese");
const i18nTablet_1 = require("./i18nTablet");
const i18nPapo_1 = require("./i18nPapo");
let _i18nMessages = simpleMerge(i18nAnamnese_1.anamneseI18nMessages, i18nTablet_1.tabletI18nMessages);
_i18nMessages = simpleMerge(_i18nMessages, i18nPapo_1.papoI18nMessages);
exports.i18nMessages = _i18nMessages;
function simpleMerge(obj1, obj2) {
    const result = Object.assign({}, obj1);
    for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
            if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
                // If the value is an object and not an array, recursively merge it
                result[key] = simpleMerge(result[key] || {}, obj2[key]);
            }
            else {
                // Otherwise, assign the value to the result object
                result[key] = obj2[key];
            }
        }
    }
    return result;
}
exports.LOCALE_KEY_TO_LANGUAGE_NAME = {
    de: '🇩🇪 Deutsch',
    en: '🇬🇧 English',
    it: '🇮🇹 Italiano',
    ru: '🇷🇺 Русский',
    ar: '🇦🇪 عربى',
    es: '🇪🇸 Español',
    fr: '🇫🇷 Français',
    ja: '🇯🇵 日本語',
    pl: '🇵🇱 Polskie',
    tr: '🇹🇷 Türk',
    fa: '🇮🇷 فارسی', // Farsi
    gr: '🇬🇷 Ελληνικά', // Griechisch
    nl: '🇳🇱 Nederlands', // Niederländisch
    po: '🇵🇹 Português', // Portugiesisch
    pt: '🇧🇷 Português (BR)', // Brasilianisches Portugiesisch
    rm: '🇨🇭 Romansh', // Rätoromanisch
    ch: '🇨🇳 中文', // Chinesisch
    ko: '🇰🇷 한국어', // Koreanisch
    ur: '🇵🇰 اردو', // Urdu
    af: '🇿🇦 Afrikaans', // Afrikaans
    al: '🇦🇱 Shqip', // Albanisch
    bo: '🇧🇴 Aymara', // Bolivianisch
    bu: '🇧🇬 Български', // Bulgarisch
    cz: '🇨🇿 Čeština', // Tschechisch
    dt: '🇩🇰 Dansk', // Dänisch
    hr: '🇭🇷 Hrvatski', // Kroatisch
    hu: '🇭🇺 Magyar', // Ungarisch
    kr: '🇰🇷 한국어', // Koreanisch (Wiederholung, möglicherweise entfernen)
    pr: '🇵🇷 Puertorriqueño', // Puerto-Ricanisch
    rs: '🇷🇸 Српски', // Serbisch
    sc: '🇸🇪 Svenska', // Schwedisch
    sw: '🇨🇭 Schwiizerdütsch', // Schweizerdeutsch
    th: '🇹🇭 ไทย', // Thai
    vi: '🇻🇳 Tiếng Việt', // Vietnamesisch
    vs: '🇪🇸 Valencià', // Valencianisch
    zh: '🇨🇳 中文 (简体)', // Vereinfachtes Chinesisch
    pb: '🇵🇾 Guarani', // Paraguayisch
    ro: '🇷🇴 Română', // Rumänisch
};
let warnedMissingTranslations = new Set();
exports.vueI18nConfig = {
    messages: exports.i18nMessages,
    locale: 'de',
    fallbackLocale: 'en',
    legacy: false,
    globalInjection: true,
    // This ensures that every translation warning is only logged once.
    missing: (locale, key) => {
        let cacheKey = `${locale}##${key}`;
        if (!warnedMissingTranslations.has(cacheKey)) {
            warnedMissingTranslations.add(cacheKey);
            console.warn(`Missing ${locale} translation for ${key}`);
        }
    },
    silentTranslationWarn: true,
    silentFallbackWarn: true,
};
