"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commonCreateSummaryRowPatienten = exports.commonCreateExtendedSummaryRowPatienten = void 0;
const lodash_1 = require("lodash");
function commonCreateExtendedSummaryRowPatienten(patienten, summeryRowsConfig) {
    let history = summeryRowsConfig.map(({ yearKey, title, filter }) => {
        let leistungenYear = (0, lodash_1.map)(patienten, (l) => l.jahresVerteilung[yearKey]);
        return {
            isSummary: true,
            doFilter: filter,
            isExtended: true,
            honoraroffen: sumUpKey(leistungenYear, 'honoraroffen'),
            materialoffenSum: sumUpKey(leistungenYear, 'materialoffenSum'),
            eigenlaboroffenSum: sumUpKey(leistungenYear, 'eigenlaboroffenSum'),
            fremdlaboroffen: sumUpKey(leistungenYear, 'fremdlaboroffen'),
            honorarerstattungoffenSum: sumUpKey(leistungenYear, 'honorarerstattungoffenSum'),
            gesamt: sumUpKey(leistungenYear, 'gesamt'),
            count: (0, lodash_1.chain)(leistungenYear)
                .filter(l => l.gesamt > 0)
                .size()
                .value(),
            title: title,
        };
    });
    return [commonCreateSummaryRowPatienten(patienten), ...history];
}
exports.commonCreateExtendedSummaryRowPatienten = commonCreateExtendedSummaryRowPatienten;
function sumUpKey(list, key) {
    return (0, lodash_1.sumBy)(list, l => (l && l[key]) || 0);
}
function commonCreateSummaryRowPatienten(patienten) {
    const leistungszurechnung = patienten.map(v => v.leistungszurechnung);
    const lgesamt = sumUpKey(patienten, 'lgesamt');
    return {
        isSummary: true,
        isExtended: false,
        count: (0, lodash_1.size)(patienten),
        title: 'Summe',
        gesamt: sumUpKey(patienten, 'gesamt'),
        honorar: sumUpKey(patienten, 'honorar'),
        honorargoz: sumUpKey(patienten, 'honorargoz'),
        honorarbema: sumUpKey(patienten, 'honorarbema'),
        honorarmwst: sumUpKey(patienten, 'honorarmwst'),
        material: sumUpKey(patienten, 'material'),
        eigenlabor: sumUpKey(patienten, 'eigenlabor'),
        eigenlaborleistungen: sumUpKey(patienten, 'eigenlaborleistungen'),
        eigenlaborbeb: sumUpKey(patienten, 'eigenlaborbeb'),
        eigenlaborbel: sumUpKey(patienten, 'eigenlaborbel'),
        fremdlabor: sumUpKey(patienten, 'fremdlabor'),
        honorarerstattung: sumUpKey(patienten, 'honorarerstattung'),
        huel: sumUpKey(patienten, 'huel'),
        renichtbezahlt: sumUpKey(patienten, 'renichtbezahlt'),
        rebezahlt: sumUpKey(patienten, 'rebezahlt'),
        reerlassen: sumUpKey(patienten, 'reerlassen'),
        reerstattung: sumUpKey(patienten, 'reerlassen'),
        gesamtnetto: sumUpKey(patienten, 'gesamtnetto'),
        gesamtmwst: sumUpKey(patienten, 'gesamtmwst'),
        nagesamt: sumUpKey(patienten, 'nagesamt'),
        nakasse: sumUpKey(patienten, 'nakasse'),
        naprivat: sumUpKey(patienten, 'naprivat'),
        agesamt: sumUpKey(patienten, 'agesamt'),
        akasse: sumUpKey(patienten, 'akasse'),
        aprivat: sumUpKey(patienten, 'aprivat'),
        nagesamtanteil: (sumUpKey(patienten, 'nagesamt') / lgesamt) * 100,
        nakasseanteil: (sumUpKey(patienten, 'nakasse') / lgesamt) * 100,
        naprivatanteil: (sumUpKey(patienten, 'naprivat') / lgesamt) * 100,
        agesamtanteil: (sumUpKey(patienten, 'agesamt') / lgesamt) * 100,
        akasseanteil: (sumUpKey(patienten, 'akasse') / lgesamt) * 100,
        aprivatanteil: (sumUpKey(patienten, 'aprivat') / lgesamt) * 100,
        honorarnetto: sumUpKey(patienten, 'honorarnetto'),
        materialnetto: sumUpKey(patienten, 'materialnetto'),
        materialmwst: sumUpKey(patienten, 'materialmwst'),
        eigenlabornetto: sumUpKey(patienten, 'eigenlabornetto'),
        eigenlabormwst: sumUpKey(patienten, 'eigenlabormwst'),
        eigenlabormaterialnetto: sumUpKey(patienten, 'eigenlabormaterialnetto'),
        eigenlabormaterialmwst: sumUpKey(patienten, 'eigenlabormaterialmwst'),
        eigenlabormaterial: sumUpKey(patienten, 'eigenlabormaterial'),
        fremdlabornetto: sumUpKey(patienten, 'fremdlabornetto'),
        fremdlabormwst: sumUpKey(patienten, 'fremdlabormwst'),
        leistungszurechnung: {
            BEMAGesamt: sumUpKey(leistungszurechnung, 'BEMAGesamt'),
            BEMA1: sumUpKey(leistungszurechnung, 'BEMA1'),
            BEMA2: sumUpKey(leistungszurechnung, 'BEMA2'),
            BEMA3: sumUpKey(leistungszurechnung, 'BEMA3'),
            BEMA4: sumUpKey(leistungszurechnung, 'BEMA4'),
            BEMA5: sumUpKey(leistungszurechnung, 'BEMA5'),
            BEMABAE: sumUpKey(leistungszurechnung, 'BEMABAE'),
            BEMABU: sumUpKey(leistungszurechnung, 'BEMABU'),
            OhneZurechnung: sumUpKey(leistungszurechnung, 'OhneZurechnung'),
            GOZGesamt: sumUpKey(leistungszurechnung, 'GOZGesamt'),
            GOZA: sumUpKey(leistungszurechnung, 'GOZA'),
            GOZB: sumUpKey(leistungszurechnung, 'GOZB'),
            GOZC: sumUpKey(leistungszurechnung, 'GOZC'),
            GOZD: sumUpKey(leistungszurechnung, 'GOZD'),
            GOZE: sumUpKey(leistungszurechnung, 'GOZE'),
            GOZF: sumUpKey(leistungszurechnung, 'GOZF'),
            GOZG: sumUpKey(leistungszurechnung, 'GOZG'),
            GOZH: sumUpKey(leistungszurechnung, 'GOZH'),
            GOZJ: sumUpKey(leistungszurechnung, 'GOZJ'),
            GOZK: sumUpKey(leistungszurechnung, 'GOZK'),
            GOZL: sumUpKey(leistungszurechnung, 'GOZL'),
            GOZV: sumUpKey(leistungszurechnung, 'GOZV'),
            GOZGU: sumUpKey(leistungszurechnung, 'GOZGU'),
            GOZGAE: sumUpKey(leistungszurechnung, 'GOZGAE'),
        },
    };
}
exports.commonCreateSummaryRowPatienten = commonCreateSummaryRowPatienten;
