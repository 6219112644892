"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.folgeTermineFilter = exports.wvlFilter = exports.SpezialFilterHandler = void 0;
const lodash_1 = require("lodash");
const types_1 = require("@rose/types");
const base_1 = require("../../../base");
class SpezialFilterHandler {
    constructor(filterGroups) {
        this.filterGroups = filterGroups;
    }
    filterEntries(entries) {
        let filtered = entries;
        for (const active of this.activeFilters()) {
            filtered = filtered.filter((l) => active === null || active === void 0 ? void 0 : active.rowFilter(l));
        }
        return filtered;
    }
    activeFilters() {
        return [
            ...(0, lodash_1.chain)(this.filterGroups)
                .values()
                .filter(filterGroup => filterGroup.active)
                .map(filterGroup => filterGroup.filters.find(f => f.key === filterGroup.selected))
                .value(),
        ];
    }
    toggleFilterGroup(f) {
        this.filterGroups[f].active = !this.filterGroups[f].active;
    }
    setFilterGroupActive({ filter: f, active }) {
        this.filterGroups[f].active = active;
    }
    filterGroupSelectedChanged(opts) {
        if (opts.filterValue) {
            // check if filterValue is already selected and active
            if (this.filterGroups[opts.filterGroup].selected === opts.filterValue &&
                this.filterGroups[opts.filterGroup].active) {
                // deselect filter
                this.setFilterGroupActive({ filter: opts.filterGroup, active: false });
            }
            else {
                // activate and select filter
                this.setFilterGroupActive({ filter: opts.filterGroup, active: true });
                this.filterGroups[opts.filterGroup].selected = opts.filterValue;
            }
        }
        else {
            this.setFilterGroupActive({ filter: opts.filterGroup, active: true });
        }
    }
    filterGroupsWithCounts(entries) {
        for (const fk of (0, lodash_1.keys)(this.filterGroups)) {
            let atLeastOneFilterWithEntries = false;
            let filterGroup = this.filterGroups[fk];
            filterGroup.filters.forEach(subFilter => {
                let fr = (0, lodash_1.filter)(entries, subFilter.rowFilter);
                subFilter.entryCount = fr.length;
                subFilter.patientCount = (0, lodash_1.uniqBy)(fr, rd => rd.patid).length;
                if (subFilter.entryCount > 0) {
                    atLeastOneFilterWithEntries = true;
                }
            });
            let selectedOrLastFilter = filterGroup.filters.find(f => f.key === filterGroup.selected);
            filterGroup.entryCount = selectedOrLastFilter === null || selectedOrLastFilter === void 0 ? void 0 : selectedOrLastFilter.entryCount;
            filterGroup.patientCount = selectedOrLastFilter === null || selectedOrLastFilter === void 0 ? void 0 : selectedOrLastFilter.patientCount;
            filterGroup.atLeastOneFilterWithEntries = atLeastOneFilterWithEntries;
        }
        return Object.assign({}, this.filterGroups);
    }
    activeFilterKeys() {
        return Object.entries(this.filterGroups)
            .filter(([key, f]) => f.active)
            .map(([key, f]) => {
            var _a;
            const subFilterKey = (_a = (0, lodash_1.find)(f.filters, subFilter => subFilter.key === f.selected)) === null || _a === void 0 ? void 0 : _a.key;
            return `${key}.${subFilterKey}`;
        });
    }
    setFilterStateFromViewState(filterString) {
        try {
            let filterArray = filterString.split(',');
            let groupFilterKeys = filterArray.filter(key => key.includes('.'));
            groupFilterKeys.forEach(key => {
                let [groupKey, subKey] = key.split('.');
                this.filterGroupSelectedChanged({ filterGroup: groupKey, filterValue: subKey });
                this.setFilterGroupActive({ filter: groupKey, active: true });
            });
        }
        catch (e) { }
    }
    getViewStateString() {
        let activeFilterKeys = this.activeFilterKeys();
        if (activeFilterKeys.length) {
            return activeFilterKeys.join(',');
        }
        else {
            return false;
        }
    }
}
exports.SpezialFilterHandler = SpezialFilterHandler;
// Termin spezial filter
exports.wvlFilter = {
    frist: {
        name: 'Frist',
        active: false,
        selected: 'frist',
        filters: [
            {
                key: 'frist',
                name: 'Frist gesetzt',
                rowFilter: rd => rd.wiedervorlage,
                help: 'Leistungen für die eine Frist gesetzt wurde, unabhängig davon ob das Fälligkeitsdatum in der Zukunft liegt oder bereits überschritten worden ist',
            },
            {
                key: 'fristabgelaufen',
                name: 'Frist fällig',
                rowFilter: rd => rd.wiedervorlage && (0, base_1.roseDayjs)(rd.wiedervorlage).isBefore((0, base_1.roseDayjs)()),
                help: 'Leistungen für die eine Frist gesetzt wurde und deren Fälligkeitsdatum überschritten worden ist',
            },
        ],
    },
};
exports.folgeTermineFilter = {
    folgetermine: {
        name: 'Folgetermine',
        selected: 'ohneFolgetermine',
        active: false,
        filters: [
            {
                key: 'ohneFolgetermine',
                name: 'Ohne Folgetermine',
                rowFilter: rd => !(0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN),
            },
            {
                key: 'mitFolgtermine',
                name: 'Mit Folgetermine',
                rowFilter: rd => (0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN),
            },
            {
                key: 'baldtermine',
                name: 'Termin binnen 7 Tagen',
                rowFilter: rd => (0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN &&
                    (0, base_1.roseDayjs)(ft.tag).isBetween((0, base_1.roseDayjs)(), (0, base_1.roseDayjs)().add(7, 'days'))),
            },
            {
                key: 'keinterminin4wo',
                name: 'Kein Termin binnen 4 Wochen',
                rowFilter: rd => (0, lodash_1.every)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN && (0, base_1.roseDayjs)(ft.tag).isAfter((0, base_1.roseDayjs)().add(4, 'weeks'))),
            },
            {
                key: 'keinterminin8wo',
                name: 'Kein Termin binnen 8 Wochen',
                rowFilter: rd => (0, lodash_1.every)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN && (0, base_1.roseDayjs)(ft.tag).isAfter((0, base_1.roseDayjs)().add(8, 'weeks'))),
            },
            {
                key: 'keinterminin4wo',
                name: 'Kein Termin binnen 12 Wochen',
                rowFilter: rd => (0, lodash_1.every)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN && (0, base_1.roseDayjs)(ft.tag).isAfter((0, base_1.roseDayjs)().add(12, 'weeks'))),
            },
            {
                key: 'ohneOffeneTermine',
                name: 'Ohne offene Termine',
                rowFilter: rd => !(0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN) &&
                    !(0, lodash_1.some)(rd.folgetermine, ft => ft.status === types_1.TerminStatusType.OFFEN),
            },
            {
                key: 'mitOffenenTerminen',
                name: 'Mit offenen Terminen',
                rowFilter: rd => !(0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN) &&
                    (0, lodash_1.some)(rd.folgetermine, ft => ft.status === types_1.TerminStatusType.OFFEN),
            },
        ],
    },
};
