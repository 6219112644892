/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-center"},[(!_vm.skipAlarm && _vm.counterValue !== undefined)?[(_vm.counterValue >= 0)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"left-button",attrs:{"color":"primary lighten-1","x-small":"","height":"32"},on:{"click":_vm.decrease}},'v-btn',attrs,false),on),[(_vm.counterValue > 0)?_c(VIcon,{staticClass:"mx-0",attrs:{"x-small":""}},[_vm._v("fas fa-minus")]):_vm._e(),(_vm.counterValue === 0)?_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-power-off")]):_vm._e()],1)]}}],null,false,1951202961)},[(_vm.counterValue > 1)?_c('span',[_vm._v("Score-Abzug verringern")]):_vm._e(),(_vm.counterValue === 1)?_c('span',[_vm._v("Kein Score-Abzug, nur Hinweis")]):_vm._e(),(_vm.counterValue === 0)?_c('span',[_vm._v("Alarm deaktivieren")]):_vm._e()]):_vm._e(),(_vm.counterValue === "-")?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"left-button",attrs:{"disabled":_vm.skipHideOption,"color":_vm.hiddenField ? "error" : "primary","x-small":"","height":"32"},on:{"click":_vm.itemClicked}},'v-btn',attrs,false),on),[(!_vm.hiddenField)?_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-eye")]):_vm._e(),(_vm.hiddenField)?_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-eye-slash")]):_vm._e()],1)]}}],null,false,530767714)},[(!_vm.hiddenField)?_c('span',[_vm._v("Feld ausblenden")]):_vm._e(),(_vm.hiddenField)?_c('span',[_vm._v("Feld einblenden")]):_vm._e()]):_vm._e(),_c(VChip,{staticClass:"counter justify-center middle mx-0",attrs:{"color":_vm.counterColor,"label":"","outlined":_vm.counterValue > 0,"disabled":_vm.hiddenField}},[_vm._v(_vm._s(_vm.counterValue))]),_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"right-button",attrs:{"color":"primary lighten-1","x-small":"","height":"32","disabled":_vm.hiddenField},on:{"click":_vm.increase}},'v-btn',attrs,false),on),[(_vm.counterValue >= 0)?_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-plus")]):_c(VIcon,{attrs:{"x-small":""}},[_vm._v("fas fa-power-off")])],1)]}}],null,false,2958271451)},[(_vm.counterValue === 0)?_c('span',[_vm._v("Alarm aktivieren")]):(_vm.counterValue > 0)?_c('span',[_vm._v("Score-Abzug erhöhen")]):_c('span',[_vm._v("Hinweis aktivieren")])])]:_vm._e(),(!_vm.skipAlarm && _vm.counterValue === undefined)?_c(VBtn,{attrs:{"disabled":_vm.skipHideOption,"color":_vm.hiddenField ? "error" : "primary lighten-1","x-small":""},on:{"click":_vm.itemClicked}},[(!_vm.hiddenField)?_c(VIcon,{staticClass:"mx-1",attrs:{"x-small":""}},[_vm._v("fas fa-eye")]):_vm._e(),(_vm.hiddenField)?_c(VIcon,{staticClass:"mx-1",attrs:{"x-small":""}},[_vm._v("fas fa-eye-slash")]):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }