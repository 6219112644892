/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TabletActionButtonsCell.vue?vue&type=template&id=375c648a&scoped=true&lang=pug&"
import script from "./TabletActionButtonsCell.vue?vue&type=script&lang=ts&"
export * from "./TabletActionButtonsCell.vue?vue&type=script&lang=ts&"
import style0 from "./TabletActionButtonsCell.vue?vue&type=style&index=0&id=375c648a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375c648a",
  null
  
)

export default component.exports