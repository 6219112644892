/* unplugin-vue-components disabled */import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center"},[_vm._l((_vm.params.context.actionIcons),function(actionIcon){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":actionIcon.color || "info","icon":"","small":""},on:{"click":function($event){return actionIcon.handler(_vm.params.data)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(_vm._s(actionIcon.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(actionIcon.tooltip))])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }