"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var vue_chartjs_1 = require("vue-chartjs");

exports["default"] = (0, vue_1.defineComponent)({
  "extends": vue_chartjs_1.Doughnut,
  props: {
    data: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    labels: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    title: {
      type: String,
      "default": function _default() {
        return '-';
      }
    },
    colors: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    showLabels: {
      type: Boolean,
      "default": false
    },
    showLegend: {
      type: Boolean,
      "default": false
    }
  },
  watch: {
    data: function data() {
      this.updateChart();
    }
  },
  mounted: function mounted() {
    this.updateChart();
  },
  methods: {
    updateChart: function updateChart() {
      // @ts-ignore
      this.renderChart({
        labels: this.showLabels ? this.labels : [],
        datasets: [{
          label: this.title,
          backgroundColor: this.colors,
          data: this.data
        }]
      }, {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.showLegend,
          position: 'bottom'
        }
      });
    }
  }
});