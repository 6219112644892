"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: null
    };
  },
  methods: {
    editItem: function editItem() {
      this.params.edit(this.params.data);
    },
    deleteItem: function deleteItem() {
      this.params["delete"](this.params.data);
    }
  }
});