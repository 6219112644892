"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadExcel = exports.setStoreFromParams = void 0;
const leistungen_1 = require("@/components/columns/leistungen");
const leistungenStore_1 = require("@/state/leistungenStore");
const teamsStore_1 = require("@/state/teamsStore");
const lodash_1 = require("lodash");
const base_1 = require("@rose/base");
const common_ui_1 = require("@rose/common-ui");
const base_2 = require("../../../base");
const exportExcel_1 = require("@rose/common-ui/src/directImports/exportExcel");
const types_1 = require("@rose/types");
const patientdetails_grid_1 = require("@rose/common-ui/src/helpers/patientdetails.grid");
function setStoreFromParams(routeParams) {
    if (routeParams) {
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.leistungsbehandler) {
            leistungenStore_1.leistungenStore.commit.setLeistungserbringerSelected(teamsStore_1.teamsStore.getters.teams.find(t => t.id === routeParams.leistungsbehandler));
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.stammbehandler) {
            leistungenStore_1.leistungenStore.commit.setTeamSelected(teamsStore_1.teamsStore.getters.teams.find(t => t.id === routeParams.stammbehandler));
        }
        if ((0, lodash_1.isBoolean)(routeParams === null || routeParams === void 0 ? void 0 : routeParams.subtractHkp)) {
            leistungenStore_1.leistungenStore.commit.setSubtractHkp(routeParams.subtractHkp);
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.patientSearchInput) {
            leistungenStore_1.leistungenStore.commit.setPatientSearchInput(routeParams.patientSearchInput);
        }
        if ((routeParams === null || routeParams === void 0 ? void 0 : routeParams.from) && (routeParams === null || routeParams === void 0 ? void 0 : routeParams.to)) {
            leistungenStore_1.leistungenStore.commit.setDate({
                startDate: routeParams.from,
                endDate: routeParams.to,
            });
            leistungenStore_1.leistungenStore.commit.setDateEnabled(true);
        }
    }
}
exports.setStoreFromParams = setStoreFromParams;
function downloadExcel() {
    var _a, _b;
    let wb = (0, exportExcel_1.createWorkBook)();
    let ws = (0, base_1.createEmptyWorkSheet)(wb, 'Leistungen');
    let exportColumns = (0, lodash_1.chain)(patientdetails_grid_1.patientExportColumns)
        .concat(leistungen_1.leistungenColumnDefs)
        .reject(cd => !!cd.noexport)
        .value();
    exportColumns.push({
        headerName: 'Honorar-MwSt',
        field: 'honorarmwstoffenSum',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Eigenlabor-MwSt',
        field: 'eigenlabormwstoffenSum',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Eigenlabor-Material-MwSt',
        field: 'eigenlabormaterialmwstoffenSum',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Fremdlabor-MwSt',
        field: 'fremdlabormwstoffenSum',
        width: 85,
        exportUnit: types_1.Units.EURO,
    }, {
        headerName: 'Material-MwSt',
        field: 'materialmwstoffenSum',
        width: 85,
        exportUnit: types_1.Units.EURO,
    });
    // write header
    let colCount = 0;
    let rowCount = 0;
    for (const cd of exportColumns) {
        (0, base_1.createCell)(ws, colCount++, rowCount, cd.headerName, types_1.Units.NONE, base_1.boldCellStyle);
    }
    // write data
    let sortedRowData = (0, lodash_1.sortBy)(leistungenStore_1.leistungenStore.getters.leistungenFiltered, r => r.name);
    for (const row of sortedRowData) {
        colCount = 0;
        rowCount++;
        for (const cd of exportColumns) {
            (0, base_1.createCell)(ws, colCount++, rowCount, (0, common_ui_1.getValueFromGrid)(row, cd), (0, common_ui_1.getUnitFromGrid)(cd));
        }
    }
    let metaData = {
        Filter: leistungenStore_1.leistungenStore.getters.patientSearchInput || '-',
        HKP: leistungenStore_1.leistungenStore.getters.subtractHkp ? 'ohne' : 'mit',
        Stammbehandler: ((_a = leistungenStore_1.leistungenStore.getters.teamSelected) === null || _a === void 0 ? void 0 : _a.name) || '-',
        Leistungerbringer: ((_b = leistungenStore_1.leistungenStore.getters.leistungserbringerSelected) === null || _b === void 0 ? void 0 : _b.name) || '-',
    };
    if (leistungenStore_1.leistungenStore.getters.dateEnabled) {
        let selectedDateFormatted = `${(0, base_2.roseDayjs)(leistungenStore_1.leistungenStore.getters.date.startDate).format('DD.MM.YYYY')} - ${(0, base_2.roseDayjs)(leistungenStore_1.leistungenStore.getters.date.endDate).format('DD.MM.YYYY')}`;
        metaData['Datum-Filter'] = selectedDateFormatted;
    }
    (0, base_1.finalizeSimpleXLSXWithMetadata)(wb, ws, metaData, colCount, rowCount, (0, lodash_1.map)(exportColumns, c => ({ wpx: Math.trunc(c.exportWidth || c.width || 80) })));
    (0, exportExcel_1.saveXLSX)(wb, 'leistungen.xlsx');
}
exports.downloadExcel = downloadExcel;
