"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    condition: {
      get: function get() {
        var _a, _b;

        return (_b = editSettingsStore_1.editSettingsStore.getters.alarme[(_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey]) === null || _b === void 0 ? void 0 : _b.conditions;
      },
      set: function set(value) {
        var _a;

        if ((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey) {
          editSettingsStore_1.editSettingsStore.commit.setAlarmCondition({
            alarmField: this.params.data.feldKey,
            condition: value
          });
        }
      }
    },
    conditions: function conditions() {
      return this.params.value;
    }
  }
});