"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leistungenColumnDefs = void 0;
const behandlerService_1 = require("@/services/behandlerService");
const leistungenStore_1 = require("@/state/leistungenStore");
const common_ui_1 = require("@rose/common-ui");
const types_1 = require("@rose/types");
const lodash_1 = require("lodash");
const behandlerService = new behandlerService_1.BehandlerService();
exports.leistungenColumnDefs = [
    {
        headerName: 'Patient',
        sortable: true,
        sort: 'asc',
        pinned: 'left',
        width: 300,
        comparator: common_ui_1.patientComparer,
        valueFormatter: params => params && (0, common_ui_1.patientDisplayText)(params.data),
        cellRendererSelector: (params) => {
            if (params.data.isSummary) {
                return { component: 'LeistungenSummary' };
            }
            return { component: 'PatientCell' };
        },
        suppressMovable: true,
        exportWidth: 180,
        noexport: true,
    },
    {
        headerName: 'Termin',
        field: 'folgetermine',
        cellClass: 'ag-cell-with-component',
        width: 30,
        sortable: true,
        resizable: true,
        comparator: common_ui_1.folgeTermineComparer,
        valueFormatter: common_ui_1.folgeTermineFormatter,
        cellRendererSelector: (params) => {
            if (params.data.isSummary) {
                return { component: null };
            }
            return { component: 'TermineCell' };
        },
        exportWidth: 80,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.folgeTermineRawDateFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Info',
        field: 'hasComments',
        cellRenderer: 'KommentarCell',
        width: 48,
        sortable: true,
        resizable: true,
        exportWidth: 150,
    },
    {
        headerName: 'Wiedervorlage',
        field: 'wiedervorlage',
        editable: true,
        cellEditor: 'DateRangeCell',
        valueFormatter: common_ui_1.dateFormatter,
        onCellValueChanged: (v) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            leistungenStore_1.leistungenStore.dispatch.saveWiedervorlage(v).then(() => console.log('saved'));
        },
        width: 125,
        resizable: true,
        sortable: true,
        suppressMovable: true,
        cellClassRules: {
        // 'cell-warning': params => {
        //   let wiedervorlage = params.data?.wiedervorlage;
        //   let ueberFaellig = wiedervorlage && roseDayjs(wiedervorlage).isBefore(roseDayjs());
        //   return ueberFaellig;
        // },
        },
    },
    {
        headerName: 'Zeitraum',
        sortable: true,
        field: 'abrechnungszeitraumoffengoz',
        headerTooltip: 'Durchschnittlicher Abrechnungszeitraum gewichtet nach Honorarumsatz',
        width: 150,
        resizable: true,
        exportUnit: types_1.Units.DAYSROUNDED,
        useFormatterForExport: false,
        cellStyle: { 'text-align': 'right' },
        valueFormatter: common_ui_1.decimalFormatter,
    },
    {
        headerName: 'Datum von',
        sortable: true,
        field: 'tagMin',
        valueFormatter: common_ui_1.dateFormatter,
        width: 90,
        resizable: true,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.dateRawFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Datum bis',
        sortable: true,
        field: 'tagMax',
        valueFormatter: common_ui_1.dateFormatter,
        width: 90,
        resizable: true,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.dateRawFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Honorar GOZ',
        field: 'honorar',
        width: 110,
        sortable: true,
        cellStyle: { 'text-align': 'right' },
        cellClass: params => (0, common_ui_1.classZeroValue)(params),
        valueFormatter: common_ui_1.euroFormatter,
        headerTooltip: 'Vom Honorar GOZ wurde der BEMA-Erstattungsanteil bereits abgezogen',
        resizable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Erstattung BEMA',
        field: 'honorarerstattung',
        width: 140,
        sortable: true,
        valueFormatter: common_ui_1.euroFormatter,
        cellStyle: { 'text-align': 'right' },
        cellClass: params => (0, common_ui_1.classZeroValue)(params),
        resizable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Material',
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        field: 'material',
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: params => (0, common_ui_1.classZeroValue)(params),
        width: 100,
        resizable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Eigenlabor',
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        field: 'eigenlabor',
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: params => (0, common_ui_1.classZeroValue)(params),
        width: 100,
        resizable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Eigenlabor-Material',
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        field: 'eigenlabormaterial',
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: params => (0, common_ui_1.classZeroValue)(params),
        width: 100,
        resizable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Fremdlabor',
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        field: 'fremdlabor',
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: params => (0, common_ui_1.classZeroValue)(params),
        width: 100,
        resizable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Gesamt',
        field: 'gesamt',
        sortable: true,
        cellStyle: { 'text-align': 'right' },
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: params => (0, common_ui_1.classZeroValue)(params),
        width: 100,
        resizable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Stammbehandler',
        field: 'hauptbehandler',
        sortable: true,
        width: 150,
        resizable: true,
        valueFormatter: (0, common_ui_1.behandlerFormatter)(behandlerService),
    },
    {
        headerName: 'Leistungserbringer',
        field: 'leistungsErbringerVerteilung',
        width: 250,
        cellRendererParams: {
            typ: 'offeneleistungen',
        },
        cellRenderer: 'LeistungserbringerCell',
        valueFormatter: (0, common_ui_1.behandlerArrayFormatter)(behandlerService, v => v.behandler),
        resizable: true,
        exportWidth: 400,
    },
    {
        headerName: 'Honorar-Leistungserbringer',
        field: 'Honorar-Leistungserbringer',
        width: 150,
        sortable: true,
        resizable: true,
        get reactiveHideCondition() {
            return !leistungenStore_1.leistungenStore.getters.leistungserbringerSelected;
        },
        noexport: true,
        valueFormatter: common_ui_1.euroFormatter,
        valueGetter: p => {
            var _a;
            const leistungsbehandler = leistungenStore_1.leistungenStore.getters.leistungserbringerSelected;
            if (leistungsbehandler && ((_a = p.data) === null || _a === void 0 ? void 0 : _a.leistungsErbringerVerteilung)) {
                return (0, lodash_1.sumBy)(p.data.leistungsErbringerVerteilung.filter((lev) => lev.behandler === leistungsbehandler.id), (e) => e.honorar);
            }
            else {
                return undefined;
            }
        },
    },
    // pinnedRowCellRenderer: (p: any) => {
    //   if (p.data.honorarLeistungserbringer) {
    //     return '<span>' + `${numeral(p.data.honorarLeistungserbringer, { fractionDigits: 0 })}` + '</span>';
    //   }
    //   return '<span></span>';
    // },
];
