import { defineComponent } from 'vue';
import { authStore } from './state/authStore';
import { clientStateStore } from './state/clientStateStore';
export default defineComponent({
  name: 'App',
  data: function data() {
    return {};
  },
  computed: {
    cid: function cid() {
      return authStore.getters.cid;
    },
    initialized: function initialized() {
      return authStore.getters.initialized;
    },
    clientDataInitialized: function clientDataInitialized() {
      return clientStateStore.getters.clientDataInitialized;
    }
  }
});