"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    text: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      "default": 'auto'
    },
    iconClass: {
      type: String,
      "default": undefined
    }
  },
  computed: {
    tooltipText: function tooltipText() {
      var md = (0, common_ui_1.markdownify)(this.text); // console.log('md help', this.text, md);

      return md;
    }
  }
});