"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonFolgeTermineTerminInfoResolverService = void 0;
const lodash_1 = require("lodash");
const base_resolver_service_1 = require("./base-resolver.service");
const base_resolver_misc_1 = require("./base-resolver.misc");
const fakerDe_1 = require("../fakerDe");
class CommonFolgeTermineTerminInfoResolverService extends base_resolver_service_1.BaseResolverService {
    constructor() {
        super(...arguments);
        this.name = 'termine ft';
        this.maxRetries = 5;
    }
    extractDataObjects(entry) {
        return (0, lodash_1.chain)(entry.folgetermine).compact().uniqBy(this.extractId).value();
    }
    extractId(entry) {
        return entry.extid;
    }
    extractInfoKey(item) {
        return item.extid;
    }
    fakeInfo(dobjs) {
        return Promise.resolve((0, lodash_1.map)(dobjs, o => {
            let pn = {
                extid: o.extid || '',
                kommentar: fakerDe_1.fakerDe.lorem.sentence(10),
            };
            return pn;
        }));
    }
    blurInfo(dobjs) {
        return this.fakeInfo(dobjs);
    }
    setResolvedInfo(e, getResolvedInfo) {
        let result = { changedIds: [], failedIds: [] };
        if (e.folgetermine) {
            for (const tmk of e.folgetermine) {
                const tmkResult = (0, base_resolver_misc_1.handleTMK)(tmk, getResolvedInfo, result);
                if (tmkResult) {
                    result.changedIds.push(tmkResult);
                }
            }
        }
        return result;
    }
}
exports.CommonFolgeTermineTerminInfoResolverService = CommonFolgeTermineTerminInfoResolverService;
