import { customCellRenderers } from '@/components/cells/cellComponents';
import RoseLoader from '@/components/Loader.vue';
import RoseMeasureParentSize from '@/components/RoseMeasureParentSize.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { AgGridVue } from 'ag-grid-vue';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';
import { Pane, Splitpanes } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Vuedraggable from 'vuedraggable';
import VueLogger from 'vue-logger-plugin';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isProd } from '@rose/common-ui';

const isProduction = isProd;

library.add(fas, fad, far);

export function registerGlobalComponents() {
  Vue.component('RoseLoader', RoseLoader);
  Vue.component('RoseMeasureParentSize', RoseMeasureParentSize);
  Vue.component('VueSelect', vSelect);
  Vue.component('DateRangePicker', DateRangePicker);
  Vue.component('Splitpanes', Splitpanes as any);
  Vue.component('FontAwesomeIcon', FontAwesomeIcon);
  Vue.component('Pane', Pane as any);
  Vue.component('Draggable', Vuedraggable);
  Vue.component('AgGridVue', AgGridVue);
  Object.entries(customCellRenderers).forEach(([key, value]) => {
    Vue.component(key, value);
  });

  Vue.use(VTooltip, {
    popover: {
      defaultContainer: '[data-app]',
      defaultBoundariesElement: document.body,
    },
  });
  VueClipboard.config.autoSetContainer = true;
  Vue.use(VueClipboard);
  Vue.use(VueLogger, {
    enabled: true,
    level: isProduction ? 'error' : 'debug',
  });
}
