"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.editProfileStore = void 0;
const direct_vuex_1 = require("direct-vuex");
const authStore_1 = require("@/state/authStore");
const lodash_1 = require("lodash");
const data_1 = require("@/services/data");
const jsondiffpatch = __importStar(require("jsondiffpatch"));
const jsonpatch = __importStar(require("fast-json-patch"));
const editProfileState = {
    editProfile: {},
};
const { store, rootActionContext } = (0, direct_vuex_1.createDirectStore)({
    strict: process.env.NODE_ENV !== 'production',
    state: () => editProfileState,
    getters: {
        isModified(state) {
            if (!(0, lodash_1.isEqual)(authStore_1.authStore.state.profile, state.editProfile)) {
                console.log('profile modified', state.editProfile);
                return true;
            }
            return false;
        },
        getCurrentProfileDiff(state) {
            return jsondiffpatch
                .create({
                objectHash: function (obj, index) {
                    // try to find an id property, otherwise just use the index in the array
                    return obj.id || obj.name || '$$index:' + index;
                },
            })
                .diff(authStore_1.authStore.state.profile, state.editProfile);
        },
        getCurrentEditingChanges(state) {
            // diff with checks
            if (!authStore_1.authStore.state.profile) {
                return [];
            }
            return jsonpatch.compare(authStore_1.authStore.state.profile, state.editProfile, true);
        },
    },
    mutations: {
        setDraftProfile(state, profile) {
            state.editProfile = profile;
        },
        setPraxisName(state, praxisName) {
            state.editProfile.praxisName = praxisName;
        },
        setPraxisKurzName(state, praxisKurzName) {
            state.editProfile.praxisKurzName = praxisKurzName;
        },
        setPraxisTelefon(state, praxisTelefon) {
            state.editProfile.praxisTelefon = praxisTelefon;
        },
        setPraxisEmail(state, praxisEmail) {
            state.editProfile.praxisEmail = praxisEmail;
        },
        setPraxisNewsEmail(state, praxisNewsEmail) {
            state.editProfile.praxisNewsEmail = praxisNewsEmail;
        },
    },
    actions: {
        async init(context) {
            const { state, commit } = rootActionContext(context);
            await authStore_1.authStore.dispatch.getProfile();
            const profile = (0, lodash_1.cloneDeep)(authStore_1.authStore.state.profile) || {};
            if ((0, lodash_1.isEmpty)(state.editProfile)) {
                console.log('initializing editProfileState', profile);
            }
            else {
                console.log('reset editProfileState', profile);
            }
            commit.setDraftProfile(profile);
        },
        async save(context) {
            const { state, getters, dispatch } = rootActionContext(context);
            try {
                if (getters.isModified) {
                    console.log('EditProfileStore::updateClientProfile', state.editProfile);
                    await data_1.metricsApi.profile.updateClientProfile(state.editProfile);
                    await authStore_1.authStore.dispatch.getProfile();
                    await dispatch.init();
                }
            }
            catch (e) {
                console.error('error saving profile', e);
                throw e;
            }
        },
    },
});
exports.editProfileStore = store;
